import moment from 'moment'

import { useHeights } from '../context'

export default function AppSidebarRight() {
    const { sidebarChildren } = useHeights()

    // Right Sidebar - Wide/Desktop
    return (
        <aside className='relative flex-col hidden w-64 m-5 min-w-[16rem] overflow-hidden border rounded-md card card-shadow border-neutral-800 lg:flex'>
            <section className='flex justify-between w-full border-b border-slate-500'>
                <div className='flex flex-col flex-1 p-3'>
                    <div className='relative flex flex-row items-center justify-between'>
                        <span className='flex justify-center h-full px-2 text-2xl w-fit'>{moment().format('MMMM')}</span>
                        <span className='text-right text-neutral-600'>today</span>

                        {/* <span className='absolute top-[0.1rem] right-0  font-bold flex justify-center h-full px-2 text-4xl w-fit text-emerald-600 opacity-50'>{moment().format('MM')}</span> */}
                    </div>
                    <div className='h-[6.5rem] w-full relative'>
                        <span className='absolute top-0 right-[.3rem] text-[6rem] leading-none align-baseline'>{moment().format('DD')}</span>
                    </div>
                </div>
            </section>
            {sidebarChildren}
            <figure className='absolute bottom-0 left-0 right-0 grid h-6 grid-cols-8 border-t border-slate-500'>
                <div className='border-r border-slate-500' />
                <div className='border-r border-slate-500' />
                <div className='border-r border-slate-500' />
                <div className='border-r border-slate-500' />
                <div className='border-r border-slate-500' />
                <div className='border-r border-slate-500' />
                <div className='border-r border-slate-500' />
                <div className='opacity-50 bg-amber-600'></div>
            </figure>
        </aside>
    )
}
