import moment from 'moment/moment'
import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { PureComponent, useEffect, useState } from 'react'

import { useHeights } from '../context'
import { getBMR } from './utils'

export default function ChartCalories() {
    const [data, setData] = useState()

    const { nutritionData, activityData, userData } = useHeights()

    useEffect(() => {
        let _data = getChartData(nutritionData, activityData, userData)
        setData(_data)
    }, [nutritionData, activityData, userData])

    if (!data) return <></>

    return (
        <ResponsiveContainer width='100%' height={300}>
            <BarChart width={500} height={300} data={data} margin={{ top: 20, left: 20, right: 20, bottom: 10 }}>
                <Tooltip contentStyle={{ border: '1px solid #64748b', backgroundColor: '#FEFDF5' }} formatter={(value) => Math.round(value)} />
                <Legend verticalAlign='top' layout='vertical' wrapperStyle={{ top: 10, left: 20 }} iconType='circle' iconSize={10} className='text-sm' />
                <XAxis dataKey='date' tickLine={false} tick={<CustomizedAxisTick />} interval={0} />
                <YAxis hide />
                <Bar dataKey='Calories consumed' fill='#f59e0b' stroke='#64748b' />
                <Bar dataKey='Calories burned' fill='#0ea5e9' stroke='#64748b' />
            </BarChart>
        </ResponsiveContainer>
    )
}

function getChartData(nutrition, activity, user) {
    let result = []
    let dt = moment()

    let day_nut, day_cal, day_act, act_cal, entry, bmr
    for (let i = 0; i < 14; i++) {
        day_nut = nutrition.filter(({ dateTime }) => moment(dateTime).isSame(dt, 'date'))
        day_cal = day_nut.reduce((t, { meals }) => (t += meals.reduce((mt, { calories }) => (mt += calories ?? 0), 0)), 0)

        bmr = day_nut.reduce((t, d) => (t += d.burnedCalories), 0) || getBMR(user)

        day_act = activity.filter(({ dateTime }) => moment(dateTime).isSame(dt, 'date'))
        act_cal = day_act.reduce((t, d) => (t += d?.totalCalories ?? 0), 0)

        entry = {
            date: dt.format('DD/MM'),
            'Calories consumed': 0,
            'Calories burned': 0,
        }

        if (day_cal > 999) {
            entry['Calories consumed'] = day_cal
            entry['Calories burned'] = bmr + act_cal
        }

        result.push(entry)

        dt = dt.subtract(1, 'days')
    }

    return result.reverse()
}

class CustomizedAxisTick extends PureComponent {
    render() {
        const { x, y, stroke, payload } = this.props

        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} textAnchor='middle' fill='#666' fontSize={10}>
                    {payload.value}
                </text>
            </g>
        )
    }
}
