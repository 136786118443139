import { ArrowDownTrayIcon, ChatBubbleLeftRightIcon, Cog8ToothIcon, InboxStackIcon, PencilSquareIcon } from '@heroicons/react/24/outline'
import { NavLink, useNavigate } from 'react-router-dom'

import { NAV_LINKS } from '../app/layout'
import { useHeights } from '../context'
import logo from '../logo.png'
import ImageViewer from './image-viewer'
import ThemeButton from './theme-button'

const FORMS = [
    { name: '+ activity', link: 'cardio-activity' },
    { name: '+ strength activity', link: 'strength-activity' },
    { name: '+ meals', link: 'meals' },
    { name: '+ goals', link: 'goals' },
]

export default function AppSidebarLeft({ showSidebar, setShowSidebar, formState, setFormState }) {
    const navigate = useNavigate()
    const { isChatOpen, setChatOpen, userData } = useHeights()

    return (
        <>
            {/* Left TinyBar - Wide/Desktop */}
            <aside className='z-50 flex-col hidden w-16 px-2 py-4 space-y-4 border-r border-neutral-800 text-neutral-800 bg-dark-bg sm:flex'>
                <button className='flex flex-col items-center p-1 transition cursor-pointer hover:bg-neutral-300/50' onClick={() => navigate('/profile')}>
                    <Cog8ToothIcon className='w-6 h-6' />
                    <span className='text-xs whitespace-nowrap'>Settings</span>
                </button>
                <button className='flex flex-col items-center p-1 transition cursor-pointer hover:bg-neutral-300/50' onClick={() => setChatOpen(true)}>
                    <ChatBubbleLeftRightIcon className='w-6 h-6' />
                    <span className='text-xs whitespace-nowrap'>Advice</span>
                </button>
                <button className='flex flex-col items-center p-1 transition cursor-pointer hover:bg-neutral-300/50' onClick={() => setFormState({ isOpen: true, form: 'import' })}>
                    <ArrowDownTrayIcon className='w-6 h-6' />
                    <span className='text-xs whitespace-nowrap'>Import</span>
                </button>
            </aside>

            {/* Left MainBar - Wide/Desktop */}
            <aside className='flex-col hidden w-64 border-r bg-theme-mg border-neutral-800 sm:flex'>
                <figure className='flex items-center w-56 px-4 mt-4 space-x-4 cursor-pointer' onClick={() => navigate('/')}>
                    <img src={logo} className='h-8 ml-[2px]' alt='heights' />
                    {/* <span className='text-2xl font-light tracking-wide mono-font min-w-fit'>heights</span> */}
                </figure>

                <section className='mt-6'>
                    <h1 className='px-4 text-lg tracking-tight text-neutral-800'>
                        <InboxStackIcon className='inline w-5 mb-px mr-2' /> pages
                    </h1>
                    <ul className='flex-1 px-2 mt-4 ml-12 border-l border-neutral-800'>
                        {NAV_LINKS.map(({ name, link }) => (
                            <NavLink key={link} to={link} className={({ isActive }) => `flex items-center px-2 text-sm transition cursor-pointer h-8 whitespace-nowrap text-neutral-800 ${isActive ? 'bg-dark-bg' : 'hover:bg-neutral-300/50'}`}>
                                {`${name}`}
                            </NavLink>
                        ))}
                    </ul>
                </section>
                <section className='mt-6'>
                    <h1 className='px-4 text-lg tracking-tight text-neutral-800'>
                        <PencilSquareIcon className='inline w-5 mb-px mr-2' /> track
                    </h1>
                    <ul className='flex-1 px-2 mt-4 ml-12 border-l border-neutral-800'>
                        {FORMS.map(({ name, link }) => (
                            <li key={name} onClick={() => setFormState({ isOpen: true, form: link })} className={`flex items-center px-2 text-sm transition cursor-pointer h-8 whitespace-nowrap text-neutral-800 ${formState.isOpen && formState.form === link ? 'bg-dark-bg' : 'hover:bg-neutral-300/50'}`}>
                                {`${name}`}
                            </li>
                        ))}
                    </ul>
                </section>
            </aside>

            {/* Left - Narrow/Mobile */}
            {showSidebar && (
                <>
                    <div className='fixed bottom-0 left-0 right-0 top-14 z-30 bg-[rgba(0,0,0,0.1)] sm:hidden' onClick={() => setShowSidebar(false)}></div>
                    <aside className='fixed bottom-0 left-0 z-40 flex flex-col justify-between border-r shadow-xl border-slate-500 bg-theme-mg sm:hidden top-14 w-52 text-neutral-900' style={{ boxShadow: '20px 0px 25px -5px rgb(0 0 0 / 0.2)' }} onClick={(e) => e.stopPropagation()}>
                        <div className='flex flex-col'>
                            <div className='flex items-center px-4 py-4 space-x-4 overflow-hidden cursor-pointer' onClick={() => navigate(`/profile`)}>
                                {userData?.image && (
                                    <div className='relative w-16 h-16'>
                                        {/* <div className='absolute w-16 h-16 rounded-full opacity-50 top-[.3rem] left-[.3rem] bg-emerald-600' /> */}
                                        <ImageViewer image={userData.image} className='absolute top-0 left-0 object-cover w-16 h-16 border border-black rounded-full' />
                                    </div>
                                )}
                                <span className='text-2xl text-neutral-800'>{userData?.name?.split(' ')[0] ?? ''}</span>
                            </div>

                            <nav className='flex flex-col items-start px-2 text-sm select-none whitespace-nowrap'>
                                {NAV_LINKS.map(({ name, link }) => (
                                    <NavLink key={link} to={link} className={({ isActive }) => `flex items-center px-2 text-sm transition cursor-pointer h-8 whitespace-nowrap text-neutral-800 ${isActive ? 'bg-dark-bg' : 'hover:bg-neutral-300/50'}`}>
                                        {`${name}`}
                                    </NavLink>
                                ))}
                                <NavLink to='/profile' onClick={() => setShowSidebar(false)} className={({ isActive }) => `flex items-center px-2 text-sm transition cursor-pointer h-8 whitespace-nowrap text-neutral-800 ${isActive ? 'bg-dark-bg' : 'hover:bg-neutral-300/50'}`}>
                                    profile
                                </NavLink>
                                <span className={`flex items-center px-2 text-sm transition cursor-pointer h-8 whitespace-nowrap text-neutral-800 ${isChatOpen ? 'bg-dark-bg' : 'hover:bg-neutral-300/50'}`} onClick={() => setChatOpen(true)}>
                                    advice
                                </span>
                                <span
                                    className={`flex items-center px-2 text-sm transition cursor-pointer h-8 whitespace-nowrap text-neutral-800 ${formState.isOpen && formState.form === 'import' ? 'bg-dark-bg' : 'hover:bg-neutral-300/50'}`}
                                    onClick={() => {
                                        setShowSidebar(false)
                                        setFormState({ isOpen: true, form: 'import' })
                                    }}
                                >
                                    import
                                </span>
                            </nav>

                            <h1 className='px-4 py-2 text-lg font-semibold tracking-tighter text-neutral-800'>Record</h1>

                            <ul className='flex-1 px-2'>
                                {FORMS.map(({ name, link }) => (
                                    <li key={name} onClick={() => setFormState({ isOpen: true, form: link })} className={`flex items-center px-2 text-sm transition cursor-pointer h-8 whitespace-nowrap text-neutral-800 ${formState.isOpen && formState.form === link ? 'bg-dark-bg' : ' hover:bg-neutral-300/50'}`}>
                                        {`${name}`}
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <div className='flex px-4 py-2'>
                            <ThemeButton onClick={() => navigate('/logout')}>logout</ThemeButton>
                        </div>
                    </aside>
                </>
            )}
        </>
    )
}
