import moment from 'moment'
import { ChevronDownIcon, ChevronUpIcon, PencilIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'

import { api, api_delete } from '../services/api.service'

import Card from '../components/card'
import Loading from '../components/loading'
import ThemeButton, { TrashBtn } from '../components/theme-button'
import { DEFAULT_FIELDS } from '../components/utils'

import { DateField, NumberField, SelectField, TextAreaField, TextField } from '../forms/fields'
import ImageField from '../forms/fields/image-upload'
import FormWrapper from '../forms/form-wrapper'
import { GoalFormBody } from '../forms/goal-form'

import { useHeights } from '../context'

function byType(items) {
    let result = {
        strength: [],
        run: [],
        cycle: [],
        walk: [],
    }
    if (!items) {
        return result
    }
    for (const item of items) {
        result[item.type].push(item.name)
    }
    return result
}

export default function Profile() {
    const { userData, goalsData, updateGoal, reload } = useHeights()

    useEffect(() => {}, [userData, goalsData])

    function updateUserFields(updatedFields) {
        api(`${process.env.REACT_APP_API_URL}/app/settings/fields`, { data: { customFields: updatedFields } }).then((x) => reload())
    }

    function deleteGoal(goalId) {
        api_delete(`${process.env.REACT_APP_API_URL}/app/goal/${goalId}`).then((x) => reload())
    }

    if (!userData) return <Loading />

    return (
        <div className='flex flex-col flex-1 px-1 py-2 space-y-8 sm:py-6 sm:px-6'>
            <Card header='Athlete Info'>
                <FormWrapper url='app/settings' noId noStyle hideButton callback={() => reload()}>
                    {(values, setValues) => (
                        <div className='grid items-center grid-cols-4 px-4'>
                            <div>Photo</div>
                            <div className='flex items-center w-full col-span-3 pb-2 pl-4 border-l border-slate-500'>
                                <ImageField value={values?.image ?? ''} onChange={(val) => setValues({ ...values, image: val })} />
                            </div>
                            <div>Name</div>
                            <div className='flex items-center w-full col-span-3 pl-4 border-l border-slate-500 h-11'>
                                <TextField className='w-full -mt-1' required value={values?.name ?? ''} onChange={(val) => setValues({ ...values, name: val })} />
                            </div>
                            <div>Email</div>
                            <div className='flex items-center w-full col-span-3 pl-4 border-l border-slate-500 h-11'>
                                <TextField className='w-full -mt-1' required value={values?.email ?? ''} onChange={(val) => setValues({ ...values, email: val })} />
                            </div>
                            <div>Birthday</div>
                            <div className='flex items-center w-full col-span-3 pl-4 border-l border-slate-500 h-11'>
                                <DateField className='w-full -mt-1' value={values?.birthday} onChange={(val) => setValues({ ...values, birthday: moment(val).toDate() })} />
                            </div>
                            <div>Sex</div>
                            <div className='flex items-start w-full col-span-3 pl-4 border-l border-slate-500 h-11'>
                                <SelectField
                                    className='w-full h-[41.33px]'
                                    value={values?.sex}
                                    onChange={(val) => setValues({ ...values, sex: val })}
                                    options={[
                                        { value: 'female', text: 'Female' },
                                        { value: 'male', text: 'Male' },
                                        { value: 'other', text: 'Other' },
                                    ]}
                                />
                            </div>
                            <div>Weight {'(kg)'}</div>
                            <div className='flex items-center w-full col-span-3 pl-4 border-l border-slate-500 h-11'>
                                <NumberField className='w-full -mt-1' value={values?.weight} onChange={(val) => setValues({ ...values, weight: val })} />
                            </div>
                            <div>Height {'(cm)'}</div>
                            <div className='flex items-center w-full col-span-3 pl-4 border-l border-slate-500 h-11'>
                                <NumberField className='w-full -mt-1' value={Number(values?.height ?? 0) ?? 0} onChange={(val) => setValues({ ...values, height: val })} />
                            </div>
                            <div>Coach Persona</div>
                            <div className='flex items-center w-full col-span-3 pl-4 border-l border-slate-500'>
                                <TextAreaField value={values?.coachPersona ?? "I'd like my coach to be..."} onChange={(val) => setValues({ ...values, coachPersona: val })} className='w-full mb-2 -mt-1' />
                            </div>
                            <div className='flex items-center justify-end px-4 -mx-4 border-t border-slate-500 h-11 col-span-full'>
                                <ThemeButton type='submit' className=''>
                                    Save
                                </ThemeButton>
                            </div>
                        </div>
                    )}
                </FormWrapper>
            </Card>

            <Card header='Goals'>
                <div className='px-4'>
                    {goalsData.length === 0 && <div className='py-4 text-center text-neutral-600'>Set a goal and your AI coach will help you to achieve it!</div>}
                    <ul className='pt-2 divide-y divide-slate-700 divide-dotted'>
                        {goalsData.map((g) => (
                            <GoalRow key={g._id} data={g} onDelete={() => deleteGoal(g._id)} onChange={(changed) => updateGoal(changed)} callback={() => reload()} />
                        ))}
                    </ul>
                    <NewGoalBlock onConfirm={() => reload()} />
                </div>
            </Card>

            <Card header='My Custom Fields'>
                <div className='p-4 text-center border-b border-slate-500 text-neutral-600'>These are the fields that will appear on all activities of each type. Some useful defaults will always appear, but you can also add your own to capture what's most important to you.</div>
                <div className='p-4 space-y-4 '>
                    {Object.entries(byType(userData.customFields)).map(([category, list], idx) => (
                        <CustomFieldManager
                            key={category}
                            category={category}
                            list={list}
                            onAdd={(newField) => updateUserFields([...(userData?.customFields ?? []), { type: category, name: newField }])}
                            onDelete={(deleteFieldName) =>
                                updateUserFields(
                                    userData.customFields.filter(({ name, type }) => {
                                        if (type === category && name === deleteFieldName) {
                                            return false
                                        } else {
                                            return true
                                        }
                                    })
                                )
                            }
                        />
                    ))}
                </div>
            </Card>
        </div>
    )
}

const GoalRow = ({ data, onDelete, onChange, callback }) => {
    const [isUpdating, setUpdating] = useState(false)
    const [isOpen, setOpen] = useState(false)

    return (
        <li className='flex flex-col'>
            {isUpdating ? (
                <GoalFormBody
                    id={data._id}
                    className='pb-2 border-b border-slate-500'
                    callback={() => {
                        callback()
                        setUpdating(false)
                    }}
                />
            ) : (
                <>
                    <div className='flex items-center h-11'>
                        <input type='checkbox' checked={data.isComplete} onChange={() => onChange({ ...data, isComplete: !data.isComplete })} className='mr-4' />
                        <span className='mr-2 text-sm'>{moment(data.goalDate).format('MM')}</span>
                        <span className='text-xl'>{moment(data.goalDate).format('DD')}</span>
                        <div className='flex justify-between flex-1 ml-4 text-lg'>
                            {data.goal}
                            <div className='flex items-center space-x-2 text-stone-700'>
                                <div onClick={() => setOpen(!isOpen)}>{isOpen ? <ChevronUpIcon className='w-6 h-6 cursor-pointer hover:text-neutral-600' /> : <ChevronDownIcon className='w-6 h-6 cursor-pointer hover:text-neutral-600' />}</div>
                                <PencilIcon onClick={() => setUpdating(true)} className='w-5 h-5 transition cursor-pointer hover:bg-neutral-300/50' />
                                <TrashBtn onClick={() => onDelete()} />
                            </div>
                        </div>
                    </div>
                    {isOpen && (
                        <div className='grid grid-cols-3 gap-x-4'>
                            <div className='flex h-11'>Current Status</div>
                            <div className='col-span-2'>{data?.current ?? '-'}</div>
                            <div className='flex h-11'>Notes</div>
                            <div className='col-span-2'>{data?.notes ?? '-'}</div>
                        </div>
                    )}
                </>
            )}
        </li>
    )
}

const NewGoalBlock = ({ onConfirm }) => {
    const [isAdding, setAdding] = useState(false)

    if (isAdding) {
        return (
            <>
                <GoalFormBody
                    callback={() => {
                        setAdding(false)
                        onConfirm()
                    }}
                />
                <div className='flex justify-end py-2'>
                    <ThemeButton className='w-min' onClick={() => setAdding(false)}>
                        Cancel
                    </ThemeButton>
                </div>
            </>
        )
    }

    return (
        <div className='flex items-center justify-end h-11'>
            <ThemeButton onClick={() => setAdding(true)}>+GOAL</ThemeButton>
        </div>
    )
}

const CustomFieldManager = ({ category, list, onAdd, onDelete }) => {
    const [isOpen, setOpen] = useState(false)
    const [isAdding, setAdding] = useState(false)
    const [newField, setNewField] = useState()

    return (
        <section>
            <div className='flex justify-between text-lg text-neutral-800' onClick={() => setOpen(!isOpen)}>
                <h1>{category}</h1>
                {isOpen ? <ChevronUpIcon className='w-6 cursor-pointer text-neutral-800' /> : <ChevronDownIcon className='w-6 cursor-pointer text-neutral-800' />}
            </div>
            {isOpen && (
                <>
                    <ul className='divide-y select-none divide-slate-700 divide-dotted'>
                        {DEFAULT_FIELDS[category].map((fieldName, fieldIdx) => (
                            <li key={fieldIdx} className='flex items-center justify-between px-4 font-semibold h-11'>
                                {fieldName.split('_').join(' ')}
                            </li>
                        ))}
                        {list.map((fieldName, fieldIdx) => (
                            <li key={fieldIdx} className='flex items-center justify-between px-4 h-11'>
                                <span>{fieldName.split('_').join(' ')}</span>
                                <TrashBtn onClick={() => onDelete(fieldName)} />
                            </li>
                        ))}
                        {!isAdding && (
                            <li className='flex items-center px-4 h-11'>
                                {/* <PlusCircleIcon onClick={() => setAdding(true)} className='w-8 h-8 my-auto transition cursor-pointer text-neutral-800 hover:bg-neutral-300/50' /> */}
                                <ThemeButton onClick={() => setAdding(true)}>+FIELD</ThemeButton>
                            </li>
                        )}
                    </ul>
                    {isAdding && (
                        <div className='flex flex-wrap items-end space-y-2'>
                            <TextField value={newField} onChange={(val) => setNewField(val)} label={`New ${category} field`} className='mr-2' />
                            <div className='flex justify-center space-x-2'>
                                <ThemeButton
                                    onClick={() => {
                                        onAdd(newField.trim().split(' ').join('_').toLowerCase())
                                        setNewField('')
                                        setAdding(false)
                                    }}
                                    className='mb-2'
                                >
                                    Confirm
                                </ThemeButton>
                                <ThemeButton
                                    onClick={() => {
                                        setNewField('')
                                        setAdding(false)
                                    }}
                                    className='mb-2'
                                >
                                    Cancel
                                </ThemeButton>
                            </div>
                        </div>
                    )}
                </>
            )}
        </section>
    )
}

/*

    <Card header='Athlete Info'>
        <div className='grid items-center grid-cols-4 px-4 border-t border-slate-500'>
            <div>PHOTO:</div>
            <div className='h-full col-span-3 py-2 pl-4 border-l border-slate-500'>{userData?.image ? <ImageViewer image={userData?.image} height={200} width={100} /> : <div>Update your profile to add a profile image</div>}</div>
            <div>NAME:</div>
            <div className='h-full col-span-3 py-2 pl-4 border-l border-slate-500'>{userData?.name ?? '-'}</div>
            <div>EMAIL:</div>
            <div className='h-full col-span-3 py-2 pl-4 border-l border-slate-500'>{userData?.email ?? '-'}</div>
            <div>BIRTHDAY:</div>
            <div className='h-full col-span-3 py-2 pl-4 border-l border-slate-500'>{userData?.birthday ? moment(userData.birthday).format('MMMM Do YYYY') : '-'}</div>
            <div>SEX:</div>
            <div className='h-full col-span-3 py-2 pl-4 border-l border-slate-500'>{userData?.sex ?? '-'}</div>
            <div>WEIGHT:</div>
            <div className='h-full col-span-3 py-2 pl-4 border-l border-slate-500'>{userData?.weight ?? '-'}</div>
            <div>HEIGHT:</div>
            <div className='h-full col-span-3 py-2 pl-4 border-l border-slate-500'>{userData?.height ?? '-'}</div>
            <div>COACH PERSONA:</div>
            <div className='h-full col-span-3 py-2 pl-4 border-l border-slate-500'>{userData?.coachPersona ?? '-'}</div>
            <div className='flex justify-end p-4 -mx-4 border-t border-slate-500 col-span-full'>
                <ThemeButton onClick={() => setUpdating(true)} className='px-2 py-1'>
                    Update
                </ThemeButton>
            </div>
        </div>
    </Card>
    */
