import moment from 'moment'

export default function DateField({ label, value, onChange, className, inputClass, labelClass, onBlur, required }) {
    // if (value instanceof Date) {
    //     value = value.toISOString()
    // }
    // if (value?.length > 11) value = value.split('T')[0]

    value = moment(value).format('YYYY-MM-DD')

    return (
        <div className={className}>
            {label && (
                <label className={`block text-sm font-medium leading-6 text-neutral-600 ${labelClass}`}>
                    {label} {required && <span className='text-red-500'>*</span>}
                </label>
            )}
            <input
                required={required}
                onBlur={onBlur}
                type='date'
                value={value}
                onChange={(e) => {
                    e.preventDefault()
                    onChange(e.target.value)
                }}
                className={`bg-slate-100 w-full block rounded border-0 p-2 text-neutral-700 shadow-sm ring-1 ring-inset ring-gray-200 placeholder:text-gray-400 outline-none focus:ring-inset focus:ring-1 focus:ring-gray-200 sm:text-sm sm:leading-6 ${inputClass}`}
            />
        </div>
    )
}
