import moment from 'moment'

export const DEFAULT_FIELDS = {
    strength: ['weight', 'reps'],
    run: ['distance', 'time', 'avg_pace'],
    walk: ['distance', 'time', 'avg_pace'],
    cycle: ['distance', 'time', 'avg_speed'],
    other: ['time'],
}

export function getCombinedFieldNames(activity, userCustomFields) {
    let result = []
    result.push(...DEFAULT_FIELDS[activity.type])

    if (userCustomFields) {
        result.push(...userCustomFields.filter(({ type }) => type === activity.type).map(({ name }) => name))
    }

    return [...new Set(result)]
}

export function getBMR(user) {
    let w = 10 * user?.weight ?? 0
    let h = 6.25 * user?.height ?? 0
    let a = 5 * (user?.birthday ? moment().diff(moment(user.birthday), 'years') : 0)

    if (w === 0 || h === 0 || a === 0) {
        return user?.sex === 'female' ? 1800 : 1950
    } else {
        return (w + h - a + (user?.sex === 'female' ? -161 : 5)) * 1.1
    }
}

export function getDayNetCalories(dt, nutritionData, activityData, userData) {
    let day_nut, day_cal, day_act, act_cal, bmr

    day_nut = nutritionData.filter(({ dateTime }) => moment(dateTime).isSame(dt, 'date'))
    day_cal = day_nut.reduce((t, { meals }) => (t += meals.reduce((mt, { calories }) => (mt += calories ?? 0), 0)), 0)

    bmr = day_nut.reduce((t, d) => (t += d.burnedCalories), 0) || getBMR(userData)

    day_act = activityData.filter(({ dateTime }) => moment(dateTime).isSame(dt, 'date'))
    act_cal = day_act.reduce((t, d) => (t += d?.totalCalories ?? 0), 0)

    return (day_cal) - (bmr + act_cal)
}
