import moment from 'moment/moment'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import AnimatedBanner from '../components/animated-banner'
import Card from '../components/card'
import RecentActivities from '../components/recent-activities'
import { ArrowBtn } from '../components/theme-button'
import { getDayNetCalories } from '../components/utils'

import { useHeights } from '../context'

export default function Home() {
    const navigate = useNavigate()
    const [activities, setActivities] = useState([])
    const { setSidebarChildren, activityData, nutritionData, goalsData, userData } = useHeights()

    useEffect(() => {
        setActivities(getLastWeek(activityData))
    }, [activityData])

    useEffect(() => {
        setSidebarChildren(<RecentActivities />)
    }, [])

    return (
        <>
            <AnimatedBanner />
            <section className='mx-6'>
                <Card header='Journey'>
                    {activities.flatMap((d) => d.activities).length === 0 ? (
                        <p className='my-3 text-center'>{`${goalsData.length === 0 ? 'Define your goals and i' : 'I'}mport activity to get started!`}</p>
                    ) : (
                        <ul className='divide-y divide-slate-500 divide-dotted'>
                            {activities.map((day, idx) => (
                                <li key={idx} className={`mx-3 px-3 grid items-center grid-cols-10 transition cursor-pointer gap-x-2 h-11 hover:bg-neutral-300/50 animate-in fade-in delay-${idx * 100}`} onClick={() => navigate(`/day/${day.dateTime.format('YYYY-MM-DD')}`)}>
                                    <div className='flex items-center justify-start col-span-2'>
                                        <span className='mr-2 text-sm'>{day.dateTime.format('MM')}</span>
                                        <span className='text-sm'>{day.dateTime.format('DD')}</span>
                                    </div>
                                    <div className='col-span-3 text-sm truncate'>{[...new Set(day.activities.map((activity) => activity.type))].join(' / ').toLowerCase()}</div>
                                    <div className='col-span-2 flex space-x-[2px] truncate'>
                                        {day.activities.map(({ type }, idx) => (
                                            <span key={type + idx}>{SHAPES[type]}</span>
                                        ))}
                                        {nutritionData.find(({ dateTime, meals }) => moment(dateTime).isSame(day.dateTime, 'date') && meals?.length > 0) ? <span>{SHAPES.meal}</span> : <></>}
                                    </div>
                                    <CalorieBalance userData={userData} dayDate={day.dateTime} nutritionEntries={nutritionData} activities={day.activities} className='col-span-2 text-sm truncate' />
                                    <div className='flex items-center justify-end'>
                                        <ArrowBtn parentHover />
                                    </div>
                                </li>
                            ))}
                        </ul>
                    )}
                </Card>
            </section>
        </>
    )
}

function getLastWeek(activities) {
    const theLastWeek = []

    let dt = moment()
    let filterFn = (d, date) => date.isSame(moment(d.dateTime), 'date')
    for (let i = 0; i < 14; i++) {
        theLastWeek.push({
            dateTime: moment(dt),
            activities: activities.filter((d) => filterFn(d, dt)),
        })
        dt = dt.subtract(1, 'days')
    }

    return theLastWeek
}

const CalorieBalance = ({ dayDate, nutritionEntries, activities, className, userData }) => {
    const balance = getDayNetCalories(dayDate, nutritionEntries, activities, userData)

    let value = `${balance >= 0 ? 'surplus' : 'deficit'}: ${Math.round(Math.abs(balance))}`

    return <div className={className}>{value}</div>
}

/*
    Activity Row Shapes
*/
const SHAPES = {
    // green circle
    run: (
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' height={16} width={16}>
            <circle cx={8} cy={8} r={8} fill='#04C98E' />
        </svg>
    ),
    // yellow square
    walk: (
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' height={16} width={16}>
            <rect x={0} width={16} y={0} height={16} fill='#F0A139' />
        </svg>
    ),
    // blue triangle
    cycle: (
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' height={16} width={16}>
            <polygon points='0,16 16,16 16,0' fill='#72B0E3' />
        </svg>
    ),
    // red half circle
    strength: (
        <svg height={16} width={16} viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg' transform='rotate(90)'>
            <path d='M0 9a7 7  0 0 1 16 0' fill='#EA3424' />
        </svg>
    ),
    // red half circle
    other: (
        <svg fill='#64748b' width='16px' height='16px' viewBox='2 2 20.6 20' xmlns='http://www.w3.org/2000/svg'>
            <path d='M21.6,9.2l-9-6.5c-0.4-0.3-0.8-0.3-1.2,0l-9,6.5C2.1,9.4,1.9,9.9,2,10.3l3.4,10.6c0.1,0.4,0.5,0.7,1,0.7h11.1c0.4,0,0.8-0.3,1-0.7L22,10.3C22.1,9.9,21.9,9.4,21.6,9.2z' />
        </svg>
    ),
    // red half circle
    meal: (
        <svg fill='#72B0E3' xmlns='http://www.w3.org/2000/svg' width={16} height={16} viewBox='0.02 0.02 1.0 1.0' enableBackground='new 0 0 52 52'>
            <path fillRule='evenodd' clipRule='evenodd' d='M0.488 0.984a0.042 0.042 0 0 0 0.062 0l0.437 -0.438a0.042 0.042 0 0 0 0 -0.062l-0.436 -0.436a0.042 0.042 0 0 0 -0.062 0L0.052 0.486a0.042 0.042 0 0 0 0 0.062l0.436 0.436z' />
        </svg>
    ),
}
