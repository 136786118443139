import moment from 'moment/moment'
import { useEffect, useState } from 'react'

import { api } from '../services/api.service'

import Card from '../components/card'
import ThemeButton from '../components/theme-button'

export default function ImportCard({ callback }) {
    const [linkedAccounts, setLinkedAccounts] = useState()
    const [data, setData] = useState()
    useEffect(() => {
        api(`${process.env.REACT_APP_API_URL}/integration/linked-accounts`).then((x) => {
            setLinkedAccounts(x)
        })
    }, [])

    const redirectToWidget = () => {
        api(`${process.env.REACT_APP_API_URL}/integration`).then((x) => {
            window.open(x.url, '_blank', 'noreferrer')
        })
    }

    const getData = (id) => {
        api(`${process.env.REACT_APP_API_URL}/integration/activities/${id}`).then((x) => {
            setData(x)
        })
    }

    return (
        <Card header='Import activities'>
            <div className='flex flex-col items-center flex-1 w-full h-full p-4 text-center bg-white'>
                Connect wearables to your heights account using Terra. Click on link accounts below to get started.
                <ThemeButton className='my-3 w-fit' onClick={() => redirectToWidget()}>
                    Link Accounts
                </ThemeButton>
                <div>
                    {linkedAccounts && <div className='text-xl font-medium text-center'>Linked Accounts</div>}
                    {linkedAccounts && <div className='text-center'>Select a provider to import historical data</div>}
                    {linkedAccounts?.map((x) => (
                        <div className='p-2 my-2 border border-dashed rounded cursor-pointer border-slate-500' onClick={() => getData(x.user_id)}>
                            {x.provider}
                        </div>
                    ))}

                    {data && (
                        <>
                            {data?.message && <div>{data?.message}</div>}
                            {!data?.message && (
                                <>
                                    <div>{data?.user.provider} Activities</div>
                                    {data?.data.length === 0 && <div>No activities found</div>}
                                    <ol>
                                        {data?.data.map((x, idx) => {
                                            console.log(x)
                                            return (
                                                <li key={idx} className='mx-2 my-4 border border-gray-700 border-dashed rounded'>
                                                    <h1>{x.metadata.name}</h1>
                                                    <h2>{moment(x.metadata.start_time).format('ddd Do MMM YYYY')}</h2>
                                                    <p>Duration: {x.active_durations_data.activity_seconds} seconds</p>
                                                    <p>Distance: {x.distance_data.summary.distance_meters} metres</p>
                                                    <p>Calories: {x.calories_data.total_burned_calories} kcal</p>
                                                </li>
                                            )
                                        })}
                                    </ol>
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
        </Card>
    )
}
