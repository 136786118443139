// Fixed width
export function XAnimatedBanner() {
    return (
        <div className='flex items-center mx-6 my-5 rounded-md overflow-hidden card-shadow height-[217px] min-height-[217px] flex-1 border border-red-500'>
            <svg width='100%' height='217' viewBox='0 0 835 217' fill='none' xmlns='http://www.w3.org/2000/svg'>
                {/* bg */}
                <rect x='0.620117' y='0.5' width='833.879' height='216' fill='transparent' stroke='#64748b' rx='6' />

                {/* right tramlines */}
                <path d='M754.575 113C754.575 73.2355 790.941 41 834.452 41' stroke='#64748b' className='duration-2000 animate-in fade-in' />
                <path d='M774.272 113C774.272 83.1766 801.821 59 834.454 59' stroke='#64748b' className='duration-1500 animate-in fade-in' />
                <path d='M793.966 113C793.966 92.6177 812.696 77 834.451 77' stroke='#64748b' className='duration-1000 animate-in fade-in' />
                <path d='M813.663 113C813.663 103.059 823.576 95 834.453 95' stroke='#64748b' className='duration-500 animate-in fade-in' />
                <line x1='754.528' y1='113' x2='754.528' y2='217' stroke='#64748b' className='duration-2000 animate-in fade-in' />
                <line x1='774.223' y1='113' x2='774.223' y2='217' stroke='#64748b' className='duration-1500 animate-in fade-in' />
                <line x1='793.917' y1='113' x2='793.917' y2='217' stroke='#64748b' className='duration-1000 animate-in fade-in' />
                <line x1='813.616' y1='113' x2='813.616' y2='217' stroke='#64748b' className='duration-500 animate-in fade-in' />

                {/* orange circle */}
                <path d='M217.367 81C217.367 102.791 198.755 120.5 175.74 120.5C152.726 120.5 134.113 102.791 134.113 81C134.113 59.2088 152.726 41.5 175.74 41.5C198.755 41.5 217.367 59.2088 217.367 81Z' fill='#F0A139' stroke='white' className='duration-3500 animate-in slide-in-from-top-64' />

                {/* top tramlines */}
                <path d='M372.15 0C372.15 22.0914 352.555 40 328.382 40C304.209 40 284.614 22.0914 284.614 0' stroke='#64748b' className='duration-500 animate-in fade-in' />
                <path d='M388.563 0C388.563 30.3757 361.619 55 328.382 55C295.144 55 268.2 30.3757 268.2 0' stroke='#64748b' className='duration-1000 animate-in fade-in' />
                <path d='M404.977 0C404.977 38.6599 370.684 70 328.382 70C286.08 70 251.788 38.6599 251.788 0' stroke='#64748b' className='duration-1500 animate-in fade-in' />
                <path d='M421.389 0C421.389 46.9442 379.748 85 328.382 85C277.015 85 235.374 46.9442 235.374 0' stroke='#64748b' className='duration-2000 animate-in fade-in' />

                {/* bottom left little triangles */}
                <path d='M6.68457 216L49.3586 176V216L6.68457 216Z' fill='#EA3424' className='duration-1000 animate-in fade-in' />
                <path d='M49.3584 216L92.0324 176V216L49.3584 216Z' fill='#215331' className='duration-2000 animate-in fade-in' />
                <path d='M91.0332 216L133.707 176V216L91.0332 216Z' fill='#F0A139' className='animate-in fade-in duration-2500' />
                <path d='M91 177L133 139V177L91 177Z' fill='#72B0E3' className='animate-in fade-in duration-3500' />

                {/* bottom left big triangle */}
                <path d='M218 138L133 216V138L218 138Z' fill='#EE7F7F' className='animate-in fade-in duration-5000' />

                {/* triangles */}
                {/* light blue */}
                <path d='M400 140L484.854 61V139L400.6 139Z' fill='#72B0E3' className='duration-2500 animate-in slide-in-from-bottom-72 slide-in-from-left-72' />
                {/* red */}
                <path d='M316 216L401 139V216L316 216Z' fill='#EA3424' className='duration-1500 animate-in slide-in-from-bottom-64 slide-in-from-left-64' />
                {/* orange */}
                <path d='M401 216L485 139V216L401 216Z' fill='#F0A139' className='duration-2000 animate-in slide-in-from-bottom-64' />
                <path d='M485 216L569 139V216L485 216Z' fill='#F0A139' className='duration-1500 animate-in slide-in-from-bottom-64' />
                {/* slate */}
                <path d='M484.853 139L400.599 217V139L484.853 139Z' fill='#64748b' className='duration-2000 animate-in slide-in-from-top-64' />
                <path d='M569.107 139L484.854 217V139L569.107 139Z' fill='#64748b' className='duration-3000 animate-in slide-in-from-top-64' />

                {/*  big green square */}
                <rect x='613.97' y='41' width='82.0655' height='78' fill='#04C98E' className='animate-in duration-4000 slide-in-from-right-64' />

                {/* little square colours */}
                <rect x='0' width='17.5073' height='15' fill='#64748b' />
                <rect x='0' y='30' width='17.5073' height='15' fill='#64748b' />
                <rect x='0' y='60' width='17.5073' height='15' fill='#64748b' />

                {/* horizontal grid lines */}
                <line x1='0.120117' y1='138.5' x2='834.999' y2='138.5' stroke='#64748b' className='duration-700 animate-in slide-in-from-right-96' />
                <line x1='1.21411' y1='40.5' x2='834.999' y2='40.5' stroke='#64748b' className='duration-700 animate-in slide-in-from-left-96' />

                {/* vertical grid lines */}
                <line x1='217.366' y1='217' x2='217.366' y2='1.9974e-08' stroke='#64748b' className='duration-700 animate-in slide-in-from-top-96' />
                <line x1='133.113' y1='217' x2='133.113' y2='1.9974e-08' stroke='#64748b' className='duration-700 animate-in slide-in-from-bottom-96' />
                <line x1='48.8586' y1='217' x2='48.8586' y2='1.9974e-08' stroke='#64748b' className='duration-700 animate-in slide-in-from-top-96' />
                <line x1='484.353' y1='217' x2='484.353' y2='1.9974e-08' stroke='#64748b' className='duration-700 animate-in slide-in-from-bottom-96' />
                <line x1='568.607' y1='217' x2='568.607' y2='1.9974e-08' stroke='#64748b' className='duration-700 animate-in slide-in-from-top-96' />
                <line x1='695.536' y1='217' x2='695.536' y2='1.9974e-08' stroke='#64748b' className='duration-700 animate-in slide-in-from-bottom-96' />
            </svg>
        </div>
    )
}

// Resizable
export default function AnimatedBanner() {
    return (
        <div className='flex items-center mx-6 my-5 rounded-md overflow-hidden card-shadow height-[217px] min-height-[217px] flex-1 border border-slate-500'>
            <svg width='100%' height='217' fill='none' xmlns='http://www.w3.org/2000/svg'>
                {/* horizontal grid lines */}
                <line x1='0%' y1='138.5' x2='100%' y2='138.5' stroke='black' className='duration-700 animate-in slide-in-from-right-96' />
                <line x1='0%' y1='40.5' x2='100%' y2='40.5' stroke='black' className='duration-700 animate-in slide-in-from-left-96' />

                {/* middle */}
                <svg x='35.55%' overflow='visible'>
                    <g transform='translate(-372)'>
                        {/* top tramlines */}
                        <path d='M372.15 0C372.15 22.0914 352.555 40 328.382 40C304.209 40 284.614 22.0914 284.614 0' stroke='black' className='duration-500 animate-in fade-in' />
                        <path d='M388.563 0C388.563 30.3757 361.619 55 328.382 55C295.144 55 268.2 30.3757 268.2 0' stroke='black' className='duration-1000 animate-in fade-in' />
                        <path d='M404.977 0C404.977 38.6599 370.684 70 328.382 70C286.08 70 251.788 38.6599 251.788 0' stroke='black' className='duration-1500 animate-in fade-in' />
                        <path d='M421.389 0C421.389 46.9442 379.748 85 328.382 85C277.015 85 235.374 46.9442 235.374 0' stroke='black' className='duration-2000 animate-in fade-in' />
                    </g>
                </svg>

                {/* left */}
                <g>
                    {/* orange circle */}
                    <path d='M217.367 81C217.367 102.791 198.755 120.5 175.74 120.5C152.726 120.5 134.113 102.791 134.113 81C134.113 59.2088 152.726 41.5 175.74 41.5C198.755 41.5 217.367 59.2088 217.367 81Z' fill='#F0A139' className='duration-3500 animate-in slide-in-from-top-64' />

                    {/* bottom left little triangles */}
                    <path d='M6.68457 217L49.3586 176V217L6.68457 217Z' fill='#EA3424' className='duration-1000 animate-in fade-in' />
                    <path d='M49.3584 217L92.0324 176V217L49.3584 217Z' fill='#215331' className='duration-2000 animate-in fade-in' />
                    <path d='M91.0332 217L133.707 176V217L91.0332 217Z' fill='#F0A139' className='animate-in fade-in duration-2500' />
                    <path d='M91 177L133 139V177L91 177Z' fill='#72B0E3' className='animate-in fade-in duration-3500' />

                    {/* bottom left big triangle */}
                    <path d='M218 138L133 217V138L218 138Z' fill='#EE7F7F' className='animate-in fade-in duration-5000' />

                    {/* little square colours */}
                    <rect x='0' width='17.5073' height='15' fill='black' />
                    <rect x='0' y='30' width='17.5073' height='15' fill='black' />
                    <rect x='0' y='60' width='17.5073' height='15' fill='black' />

                    {/* vertical grid lines */}
                    <line x1='48.8586' y1='217' x2='48.8586' y2='1.9974e-08' stroke='black' className='duration-700 animate-in slide-in-from-top-96' />
                    <line x1='133.113' y1='217' x2='133.113' y2='1.9974e-08' stroke='black' className='duration-700 animate-in slide-in-from-bottom-96' />
                    <line x1='217.366' y1='217' x2='217.366' y2='1.9974e-08' stroke='black' className='duration-700 animate-in slide-in-from-top-96' />
                </g>

                {/* right */}
                <svg x='100%' overflow='visible'>
                    <g transform={`translate(-835)`}>
                        {/* right tramlines */}
                        <path d='M754.575 113C754.575 73.2355 790.941 41 835 41' stroke='black' className='duration-2000 animate-in fade-in' />
                        <path d='M774.272 113C774.272 83.1766 801.821 59 835 59' stroke='black' className='duration-1500 animate-in fade-in' />
                        <path d='M793.966 113C793.966 92.6177 812.696 77 835 77' stroke='black' className='duration-1000 animate-in fade-in' />
                        <path d='M813.663 113C813.663 103.059 823.576 95 835 95' stroke='black' className='duration-500 animate-in fade-in' />
                        <line x1='754.528' y1='113' x2='754.528' y2='217' stroke='black' className='duration-2000 animate-in fade-in' />
                        <line x1='774.223' y1='113' x2='774.223' y2='217' stroke='black' className='duration-1500 animate-in fade-in' />
                        <line x1='793.917' y1='113' x2='793.917' y2='217' stroke='black' className='duration-1000 animate-in fade-in' />
                        <line x1='813.616' y1='113' x2='813.616' y2='217' stroke='black' className='duration-500 animate-in fade-in' />

                        {/* triangles */}
                        {/* light blue */}
                        <path d='M400 140L484.854 61V139L400.6 139Z' fill='#72B0E3' className='duration-2500 animate-in slide-in-from-bottom-72 slide-in-from-left-72' />
                        {/* red */}
                        <path d='M316 217L401 139V217L316 217Z' fill='#EA3424' className='duration-1500 animate-in slide-in-from-bottom-64 slide-in-from-left-64' />
                        {/* orange */}
                        <path d='M401 217L485 139V217L401 217Z' fill='#F0A139' className='duration-2000 animate-in slide-in-from-bottom-64' />
                        <path d='M485 217L569 139V217L485 217Z' fill='#F0A139' className='duration-1500 animate-in slide-in-from-bottom-64' />
                        {/* slate */}
                        <path d='M484.853 139L400.599 217V139L484.853 139Z' fill='black' className='duration-2000 animate-in slide-in-from-top-64' />
                        <path d='M569.107 139L484.854 217V139L569.107 139Z' fill='black' className='duration-3000 animate-in slide-in-from-top-64' />

                        {/*  big green square */}
                        <rect x='613.97' y='41' width='82.0655' height='78' fill='#04C98E' className='animate-in duration-4000 slide-in-from-right-64' />

                        {/* vertical grid lines */}
                        <line x1='484.353' y1='217' x2='484.353' y2='1.9974e-08' stroke='black' className='duration-700 animate-in slide-in-from-bottom-96' />
                        <line x1='568.607' y1='217' x2='568.607' y2='1.9974e-08' stroke='black' className='duration-700 animate-in slide-in-from-top-96' />
                        <line x1='695.536' y1='217' x2='695.536' y2='1.9974e-08' stroke='black' className='duration-700 animate-in slide-in-from-bottom-96' />
                    </g>
                </svg>
            </svg>
        </div>
    )
}
