import moment from 'moment'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useHeights } from '../context'

/* 
  Right Sidebar Content
*/
export default function RecentActivities() {
    const navigate = useNavigate()
    const { activityData } = useHeights()
    const [state, setState] = useState([])

    useEffect(() => {
        setState(activityData?.sort((a, b) => (moment(a.dateTime).isBefore(moment(b.dateTime)) ? 1 : -1))?.slice(0, 5))
    }, [activityData.length])

    return (
        <section className='flex flex-col items-stretch'>
            <h1 className='flex items-center px-4 text-lg tracking-wide border-b border-neutral-800 h-11'>recent activities</h1>
            <ul className='flex flex-col justify-start px-4 divide-y divide-slate-500 divide-dotted'>
                {state.map((x, i) => (
                    <li key={x.title + i} className='flex items-center px-2 text-sm leading-8 truncate transition cursor-pointer h-11 hover:bg-neutral-300/50' onClick={() => navigate(`/activity/${x._id}`)}>
                        {x.title ?? 'Activity'}
                    </li>
                ))}
            </ul>
        </section>
    )
}
