import Card from '../components/card'

import logo from '../logo.png'

export default function AuthLayout({ children }) {
    return (
        <div className='flex flex-col h-screen bg-theme-mg'>
            <div className='flex flex-col justify-center flex-1 max-h-screen'>
                <div className='sm:mx-auto sm:w-full sm:max-w-md'>
                    <div className='flex items-center justify-center mx-auto mb-8 text-slate-700'>
                        <img src={logo} className='h-12' alt='heights' />
                        {/* <div className='text-4xl font-light tracking-wide select-none mono-font min-w-fit'>heights</div> */}
                    </div>
                </div>
                <div className='mt-4 sm:mx-auto sm:w-full sm:max-w-lg'>
                    <Card className='px-4 py-8 bg-white max-sm:border-x-0 sm:px-10'>{children}</Card>
                </div>
            </div>
        </div>
    )
}
