import moment from 'moment'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { api, api_delete } from '../services/api.service'

import Card from '../components/card'
import Confirm from '../components/confirm'
import ErrorBoundary from '../components/error-boundary'
import Loading from '../components/loading'
import ThemeButton, { TrashBtn } from '../components/theme-button'
import { getCombinedFieldNames } from '../components/utils'

import { useHeights } from '../context'
import AddFieldBlock from './add-field-block'
import { DateField, NumberField, SelectField, TextAreaField, TextField } from './fields'
import FormWrapper from './form-wrapper'

export default function CardioActivityForm(props) {
    const params = useParams()
    const navigate = useNavigate()
    const id = props.id ?? params.id

    const { userData, reload, updateActivity } = useHeights()

    const [deleteId, setDeleteId] = useState()
    const [isPending, setPending] = useState(false)

    function addFieldToUserSettings(newField) {
        api(`${process.env.REACT_APP_API_URL}/app/settings/fields`, { data: { ...userData, customFields: [...(userData?.customFields ?? []), newField] } }).then((x) => reload())
    }

    function confirmDeleteActivity() {
        api_delete(`${process.env.REACT_APP_API_URL}/app/activity/${deleteId}`).then(() => {
            setDeleteId(null)
            reload()
            if (params.id === deleteId) {
                navigate(-1)
            }
        })
    }

    async function getCalorieEstimate(activity) {
        let response = await api(`${process.env.REACT_APP_API_URL}/chat/estimate-activity-calories`, { data: activity })
        let content = response.messages[response.messages.length - 1].content
        try {
            let estimate = JSON.parse(content)
            return estimate?.calories ?? 0
        } catch {
            console.info('[ PROMPT RETURN ] Calorie estimate error catch with response: ', content)
            alert(content)
        }
        return null
    }

    async function formCallback(e, data) {
        e.preventDefault()
        setPending(true)
        if (data.terraData) {
            data.totalCalories ||= data.terraData?.calories_data?.total_burned_calories || (data.terraData?.calories_data?.net_activity_calories ?? 0)
            await updateActivity({ ...data, terraData: undefined })
        } else {
            let estimate = await getCalorieEstimate(data)
            if (estimate) {
                data.totalCalories = estimate
            }
            await updateActivity(data)
        }
        reload()
        setPending(false)
    }

    if (!userData) return <Loading></Loading>

    return (
        <>
            <Confirm open={!!deleteId} cancel={() => setDeleteId(null)} confirm={() => confirmDeleteActivity()} />
            <Card header={props.header}>
                <FormWrapper url='app/activity' id={id} callback={null} defaultValue={{ dateTime: new Date() }} className='m-4 mt-2' noStyle hideButton {...props}>
                    {(values, setValues) => (
                        <>
                            {!values.type ? (
                                <div className='flex flex-col'>
                                    <div>First, set the type of cardio</div>

                                    <div className='flex justify-between my-5 space-x-4'>
                                        <div className={`hover:bg-neutral-200 font-medium flex-1 border-slate-500 border border-dashed p-3 rounded cursor-pointer`} onClick={() => setValues({ ...values, type: 'run' })}>
                                            RUN
                                        </div>
                                        <div className={`hover:bg-neutral-200 font-medium flex-1 border-slate-500 border border-dashed p-3 rounded cursor-pointer`} onClick={() => setValues({ ...values, type: 'walk' })}>
                                            WALK
                                        </div>
                                        <div className={`hover:bg-neutral-200 font-medium flex-1 border-slate-500 border border-dashed p-3 rounded cursor-pointer`} onClick={() => setValues({ ...values, type: 'cycle' })}>
                                            CYCLE
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    {id === 'new' && <SelectField required label='TYPE' value={values.type ?? ''} onChange={(val) => setValues({ ...values, type: val })} optionListName={'activity_types'} />}

                                    <div className='grid grid-cols-1 mt-2 gap-x-2 sm:grid-cols-2'>
                                        <TextField required value={values?.title ?? ''} label='TITLE' className='w-full' onChange={(val) => setValues({ ...values, title: val })} />
                                        <DateField
                                            required
                                            label='DATE'
                                            value={values?.dateTime}
                                            onChange={(val) => {
                                                setValues({ ...values, dateTime: moment(val).toDate() })
                                            }}
                                        />
                                    </div>

                                    <TextAreaField value={values?.description ?? ''} label='DESCRIPTION' onChange={(val) => setValues({ ...values, description: val })} rows={3} />
                                    <SelectField value={values?.feeling} label='FEELING' onChange={(val) => setValues({ ...values, feeling: val })} optionListName={'activity_feeling'} labelClass='mt-2' />
                                    <TextAreaField value={values?.comments ?? ''} label='COMMENTS' onChange={(val) => setValues({ ...values, comments: val })} rows={3} />

                                    <div className='w-full my-3 border-b border-dashed border-slate-500 text-neutral-400' />

                                    <div className='grid grid-cols-5 mb-4 gap-x-4 gap-y-2'>
                                        <ErrorBoundary>
                                            {getCombinedFieldNames(values, userData?.customFields ?? []).map((name) => (
                                                <NumberField key={name} className='whitespace-nowrap' value={values?.distanceData?.hasOwnProperty(name) ? Math.round(values.distanceData[name] * 100) / 100 : null} label={name.split('_').join(' ').toLocaleUpperCase()} onChange={(val) => setValues({ ...values, distanceData: { ...values.distanceData, [name]: val } })} />
                                            ))}
                                        </ErrorBoundary>
                                        <NumberField className='whitespace-nowrap' value={values?.totalCalories} label={'CALORIES'} onChange={(val) => setValues({ ...values, totalCalories: val })} />
                                    </div>

                                    <AddFieldBlock fieldsList={getCombinedFieldNames(values, userData?.customFields ?? [])} onConfirm={(newFieldName) => addFieldToUserSettings({ type: values.type, name: newFieldName })} />

                                    <div className='flex items-center justify-end space-x-3'>
                                        <ThemeButton onClick={(e) => formCallback(e, values)}>
                                            {isPending ? (
                                                <span className='flex items-center justify-center p-[2px]'>
                                                    <Loading height={16} />
                                                </span>
                                            ) : (
                                                'Save'
                                            )}
                                        </ThemeButton>

                                        {id !== 'new' && (
                                            <TrashBtn
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    setDeleteId(id)
                                                }}
                                            />
                                        )}
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </FormWrapper>
            </Card>
        </>
    )
}
