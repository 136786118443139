import React from 'react'
import { Route, Routes } from 'react-router-dom'

import ErrorBoundary from './components/error-boundary'

import UserForm from './forms/user-form'

import AdminUsers from './admin/admin-users'
import AdminLayout from './admin/layout'
import ActivitiesList from './app/activities-list'
import ActivityView from './app/activity-view'
import DayView from './app/day-view'
import Home from './app/home'
import AppLayout from './app/layout'
import NutritionView from './app/nutrition-view'
import ObservationsView from './app/observations'
import Profile from './app/profile'
import Login from './auth/login'
import Logout from './auth/logout'
import Register from './auth/register'
import RequestReset from './auth/request-reset'
import ResetPassword from './auth/reset-password'

export default function App() {
    return (
        <Routes>
            <Route path='/login' element={<Login />} />
            <Route path='/register' element={<Register />} />
            <Route path='/request-reset' element={<RequestReset />} />
            <Route path='/passwordReset' element={<ResetPassword />} />
            <Route path='/logout' element={<Logout />} />

            <Route path='/' element={<AppLayout />}>
                <Route index element={<Home />} />
                <Route path='/profile' element={<Profile />} />

                <Route
                    path='/day/:date'
                    element={
                        <ErrorBoundary componentName='DayView component'>
                            <DayView />
                        </ErrorBoundary>
                    }
                />



                <Route
                    path='/activities'
                    element={
                        <ErrorBoundary componentName='ActivitiesList component'>
                            <ActivitiesList />
                        </ErrorBoundary>
                    }
                />

                <Route
                    path='/observations'
                    element={
                        <ErrorBoundary componentName='ObservationsView component'>
                            <ObservationsView />
                        </ErrorBoundary>
                    }
                />

                <Route
                    path='/nutrition/:date'
                    element={
                        <ErrorBoundary componentName='NutritionView component'>
                            <NutritionView />
                        </ErrorBoundary>
                    }
                />

                <Route path='/activity/:id' element={<ActivityView />} />
            </Route>

            <Route path='/admin' element={<AdminLayout />}>
                <Route index element={<AdminUsers />} />
                <Route path='/admin/manage-user/:id' element={<UserForm />} />
            </Route>
        </Routes>
    )
}
