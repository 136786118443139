import { useState } from 'react'

import ThemeButton from '../components/theme-button'

import { TextField } from './fields'

export default function AddFieldBlock({ fieldsList, onConfirm }) {
    const [isAdding, setAdding] = useState(false)
    const [name, setName] = useState('')
    // const [feedback, setFeedback] = useState()

    function isRepeat() {
        return fieldsList.map((d) => d.split(' ').join('_').toLowerCase().trim()).includes(name.split(' ').join('_').toLowerCase().trim())
    }

    function isValid() {
        if (isRepeat()) {
            // setFeedback('Field already exists')
            return false
        }
        if (name.trim() === '') {
            // setFeedback('Must set a field name')
            return false
        }

        // setFeedback(null)
        return true
    }

    return (
        <>
            {isAdding ? (
                <div className='flex flex-col'>
                    <div className='flex items-end mb-2 space-x-2'>
                        <TextField
                            value={name}
                            onChange={(val) => {
                                setName(val)
                            }}
                            label='NEW FIELD NAME'
                        />
                        <ThemeButton
                            onClick={(e) => {
                                e.preventDefault()
                                onConfirm(name)
                                setName('')
                                setAdding(false)
                            }}
                            disabled={!isValid()}
                            className='mb-2 disabled:opacity-40'
                        >
                            Confirm
                        </ThemeButton>
                        <ThemeButton
                            onClick={(e) => {
                                e.preventDefault()
                                setName('')
                                setAdding(false)
                            }}
                            className='mb-2'
                        >
                            Cancel
                        </ThemeButton>
                    </div>
                    {/* {feedback && <div className='text-sm text-rose-600'>{feedback}</div>} */}
                </div>
            ) : (
                <div className='flex'>
                    <ThemeButton
                        onClick={(e) => {
                            e.preventDefault()
                            setAdding(true)
                            setName('')
                        }}
                    >
                        +FIELD
                    </ThemeButton>
                </div>
            )}
        </>
    )
}
