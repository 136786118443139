import { useEffect } from 'react'

import Card from '../components/card'
import ChartCalories from '../components/chart-calories'
import ChartNutrition from '../components/chart-nutrition'
import ErrorBoundary from '../components/error-boundary'
import Loading from '../components/loading'
import PExpand from '../components/p-expand'
import RecentActivities from '../components/recent-activities'

import { useHeights } from '../context'

export default function ObservationsView() {
    const { setSidebarChildren, activityData } = useHeights()

    useEffect(() => {
        setSidebarChildren(<RecentActivities />)
    }, [activityData])

    return (
        <div className='pb-20 mx-6 my-5 space-y-4'>
            <div className='grid grid-cols-3 gap-4'>
                <ErrorBoundary componentName='observations'>
                    <ObserveActivity />
                    <ObserveNutrients />
                    <ObserveCalories />
                </ErrorBoundary>
            </div>
            <Card header='Nutrition patterns' className='overflow-visible'>
                <ChartNutrition />
            </Card>
            <Card header='Calorie burn'>
                <ChartCalories />
            </Card>
        </div>
    )
}

function ObserveActivity() {
    const { observationData } = useHeights()

    if (!observationData.activity) return <Loading />

    return (
        <div className='flex flex-col'>
            <Card header='Activities'>
                <div className='p-4'>
                    <div>
                        <h2 className='font-bold'>Observations</h2>
                        <PExpand className='text-sm'>{observationData?.activity?.observation}</PExpand>
                    </div>
                    <div className='pt-2 mt-2 border-t border-dotted border-slate-500'>
                        <h2 className='font-bold'>Action</h2>
                        <PExpand className='text-sm'>{observationData?.activity?.recommendation}</PExpand>
                    </div>
                </div>
            </Card>
        </div>
    )
}

function ObserveNutrients() {
    const { observationData } = useHeights()

    if (!observationData.nutrients) return <Loading />

    return (
        <div className='flex flex-col'>
            <Card header='Nutrients'>
                <div className='p-4'>
                    <div>
                        <h2 className='font-bold'>Observations</h2>
                        <PExpand className='text-sm'>{observationData?.nutrients?.observation}</PExpand>
                    </div>
                    <div className='pt-2 mt-2 border-t border-dotted border-slate-500'>
                        <h2 className='font-bold'>Action</h2>
                        <PExpand className='text-sm'>{observationData?.nutrients?.recommendation}</PExpand>
                    </div>
                </div>
            </Card>
        </div>
    )
}

function ObserveCalories() {
    const { observationData } = useHeights()

    if (!observationData.calories) return <Loading />

    return (
        <div className='flex flex-col'>
            <Card header='Net calories'>
                <div className='p-4'>
                    <div>
                        <h2 className='font-bold'>Observations</h2>
                        <PExpand className='text-sm'>{observationData?.calories?.observation}</PExpand>
                    </div>
                    <div className='pt-2 mt-2 border-t border-dotted border-slate-500'>
                        <h2 className='font-bold'>Action</h2>
                        <PExpand className='text-sm'>{observationData?.calories?.recommendation}</PExpand>
                    </div>
                </div>
            </Card>
        </div>
    )
}
