import { ArrowDownRightIcon, ArrowLeftIcon, ArrowRightIcon, Bars3BottomLeftIcon, Bars3Icon } from '@heroicons/react/24/outline'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Card from '../components/card'
import ErrorBoundary from '../components/error-boundary'
import Loading from '../components/loading'
import { AppStickyPageHeader } from '../components/page-header'
import RecentActivities from '../components/recent-activities'
import { ArrowBtn, TickBtn } from '../components/theme-button'
import { getCombinedFieldNames } from '../components/utils'

import { NumberField, TextField } from '../forms/fields'

import { useHeights } from '../context'

export default function ActivitiesList() {
    const { userData, activityData, setSidebarChildren } = useHeights()
    const [activitiesByType, setActivities] = useState()

    useEffect(() => {
        setSidebarChildren(<RecentActivities />)
    }, [])

    useEffect(() => {
        setActivities(byType(activityData))
    }, [activityData])

    if (!activitiesByType) return <Loading />

    if (Object.values(activitiesByType).length === 0) {
        return (
            <div className='flex flex-col w-full h-full p-4 text-lg text-center justify-evenly'>
                <div className=' text-neutral-700'>Record some activity, or link an account to start using heights</div>
                <div className='flex flex-col items-center mt-10 text-neutral-500'>
                    Everything you'll need is in the sidebar
                    <Bars3Icon className='block w-10 sm:hidden' />
                    <ArrowLeftIcon className='hidden w-12 h-12 sm:block' />
                </div>
                <div className='flex flex-col items-center mt-10 text-neutral-500'>
                    Start a chat with your new AI fitness coach
                    <ArrowDownRightIcon className='block w-12 lg:hidden' />
                    <ArrowRightIcon className='hidden w-12 lg:block' />
                </div>
            </div>
        )
    }

    if (!userData) return <Loading />

    return (
        <>
            <AppStickyPageHeader>All Activities by Type</AppStickyPageHeader>
            <div className='mx-1 my-4 space-y-8 sm:mx-6'>
                {Object.entries(activitiesByType)
                    .filter((d) => d[1].length > 0)
                    .map(([category, activities], idx) => (
                        <Card key={category} header={category} expandable theme={idx}>
                            {category !== 'strength' && (
                                <table className='flex flex-col m-2'>
                                    <tr className='flex space-x-2'>
                                        <th className='w-24'></th>
                                        {getCombinedFieldNames(activities[0], userData?.customFields ?? []).map((fieldName) => (
                                            <th key={fieldName} className='flex-1 font-normal text-center truncate'>
                                                {fieldName.split('_').join(' ')}
                                            </th>
                                        ))}
                                        <th className='w-8'></th>
                                    </tr>
                                    {activities
                                        .sort((a, b) => ((a.dateTime?.valueOf() ?? 0) < (b.dateTime?.valueOf() ?? 1) ? 1 : -1))
                                        .map((activity, idx) => (
                                            <CardioItem key={idx} activity={activity} fieldsList={getCombinedFieldNames(activity, userData?.customFields)} />
                                        ))}
                                </table>
                            )}
                            {category === 'strength' && (
                                <ol className='flex flex-col mx-2 divide-y divide-slate-700 divide-dotted'>
                                    {activities
                                        .sort((a, b) => ((a.dateTime?.valueOf() ?? 0) < (b.dateTime?.valueOf() ?? 1) ? 1 : -1))
                                        .map((activity, idx) => (
                                            <StrengthItem key={idx} activity={activity} fieldsList={getCombinedFieldNames(activity, userData?.customFields)} />
                                        ))}
                                </ol>
                            )}
                        </Card>
                    ))}
            </div>
        </>
    )
}

/*
Sub Components
*/

const StrengthItem = ({ activity, fieldsList }) => {
    const navigate = useNavigate()

    return (
        <ErrorBoundary componentName={`StrengthItem : ${activity?.title ?? 'untitled'} on ${activity?.dateTime ?? 'undated'}`}>
            <li className='flex flex-col py-4 first:pt-2'>
                <header className='flex items-center justify-between transition cursor-pointer hover:bg-neutral-300/50' onClick={() => navigate(`/activity/${activity._id}`)}>
                    <div className='flex items-center'>
                        <span className='flex justify-center w-24 py-2 text-white rounded bg-neutral-800'>{moment(activity.dateTime).format('MM DD YY')}</span>
                        <span className='ml-4 text-base'>{activity?.title ?? 'Strength Activity'}</span>
                    </div>
                    <ArrowBtn parentHover />
                </header>
                {activity?.exercises?.length > 0 && (
                    <table>
                        <thead>
                            <tr className='flex flex-1 mt-2 space-x-4 '>
                                <th className='flex-1 font-normal text-center truncate'>exercise</th>
                                <th className='flex-1 font-normal text-center truncate'>sets</th>
                                {fieldsList.map((fieldName) => (
                                    <th key={fieldName} className='flex-1 font-normal text-center truncate'>
                                        {fieldName.split('_').join(' ')}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {activity.exercises.map((exercise, idx) => (
                                <tr key={idx} className='flex flex-1 mb-2 space-x-4 last:mb-0'>
                                    <td className='flex-1 py-1 text-center truncate border border-dashed rounded border-neutral-300'>{exercise.name}</td>
                                    <td className='flex-1 py-1 text-center border border-dashed rounded border-neutral-300'>{exercise.sets.length}</td>
                                    {fieldsList.map((fieldName) => (
                                        <td key={fieldName} className='flex-1 py-1 text-center border border-dashed rounded border-neutral-300'>
                                            {prettyFieldValue(exercise.sets.flatMap(({ fields }) => (fields?.hasOwnProperty(fieldName) ? fields[fieldName] : null)))}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </li>
        </ErrorBoundary>
    )
}

const CardioItem = ({ activity, fieldsList }) => {
    const navigate = useNavigate()

    const { updateActivity } = useHeights()

    const [isChanged, setIsChanged] = useState(false)
    const [state, setState] = useState(null)

    useEffect(() => {
        let _state = Object.assign({}, activity)

        if (!_state.distanceData) {
            _state.distanceData = {}
        }

        for (const field of fieldsList) {
            if (!_state.distanceData?.hasOwnProperty(field)) {
                _state.distanceData[field] = ''
            }
        }
        setState(_state)
    }, [activity, fieldsList])

    function saveUpdate() {
        updateActivity(state)
        setIsChanged(false)
    }

    if (!state) return <></>

    return (
        <tr className='flex items-center mb-2 space-x-2 h-11 last:mb-0'>
            <td className='flex items-center justify-center w-24 py-2 text-white rounded bg-neutral-800'>{moment(state.dateTime).format('MM DD YY')}</td>
            {fieldsList.map((fieldName) => (
                <td className='flex-1'>
                    {typeof state.distanceData[fieldName] === 'number' ? (
                        <NumberField
                            key={fieldName}
                            value={Math.round(state.distanceData[fieldName] * 100) / 100}
                            onChange={(val) => {
                                setState({ ...state, distanceData: { ...state.distanceData, [fieldName]: val } })
                                setIsChanged(true)
                            }}
                            inputClass='text-center'
                        />
                    ) : (
                        <TextField
                            key={fieldName}
                            value={state.distanceData[fieldName]}
                            onChange={(val) => {
                                setState({ ...state, distanceData: { ...state.distanceData, [fieldName]: val } })
                                setIsChanged(true)
                            }}
                            inputClass='text-center'
                        />
                    )}
                </td>
            ))}
            <td>{isChanged ? <TickBtn onClick={() => saveUpdate()} /> : <ArrowBtn onClick={() => navigate(`/activity/${activity._id}`)} />}</td>
        </tr>
    )
}

/*
    Helpers
*/
function byType(list) {
    if (!list?.length) return {}

    let result = {
        strength: [],
        run: [],
        cycle: [],
        walk: [],
    }

    for (const item of list) {
        result[item.type]?.push(item)
    }

    return result
}

function prettyFieldValue(values) {
    if (!values && values !== 0) {
        return '-'
    }

    if (typeof values[0] !== 'number') {
        let _num = Number(values[0])
        if (isNaN(_num)) {
            return (
                <div className='flex items-center justify-center w-full'>
                    <Bars3BottomLeftIcon className='w-6 h-6 ' />
                </div>
            )
        }
        values = values.map((d) => Number(d))
    }
    values = values.filter((d) => d > 0)

    if (values.length === 0) {
        return '-'
    }

    let min = Math.min(...values)
    let max = Math.max(...values)
    if (min !== max) {
        return `${min} - ${max}`
    } else {
        return max
    }
}
