import { ArrowPathIcon, ChatBubbleLeftRightIcon, PaperAirplaneIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useEffect, useRef, useState } from 'react'

import { api } from '../services/api.service'

import { IconBtn } from '../components/theme-button'

import { TextAreaField } from '../forms/fields'

import { useHeights } from '../context'

export default function Chat() {
    const { isChatOpen, setChatOpen } = useHeights()
    const chatBoxRef = useRef()

    const [chat, setChat] = useState()
    const [message, setMessage] = useState('')
    const [isPending, setPending] = useState(false)
    const [isRefreshing, setRefreshing] = useState(false)

    useEffect(() => {
        api(`${process.env.REACT_APP_API_URL}/chat`).then((recentChat) => {
            setChat(recentChat)
        })
    }, [])

    useEffect(() => {
        if (chatBoxRef.current) {
            chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight
        }
    }, [chat, isPending])

    function sendMessage() {
        // if (isPending || message.trim() === '') {
        //     return
        // }
        setPending(true)
        api(`${process.env.REACT_APP_API_URL}/chat/${chat._id}`, { data: { message: message } }).then((x) => {
            setChat(x)
            setMessage('')
            setPending(false)
        })
    }

    function restartChat() {
        setRefreshing(true)
        api(`${process.env.REACT_APP_API_URL}/chat/new-chat`).then((x) => {
            setChat(x)
            setRefreshing(false)
        })
    }

    return (
        <>
            {isChatOpen ? (
                <section className='fixed bottom-0 left-0 z-50 max-w-3xl pb-8 overflow-hidden border-t shadow-lg bg-theme-mg border-slate-500 w-fit md:border-r md:rounded-tr-md h-fit'>
                    <div className='flex items-center justify-between px-4 py-2 border-b border-slate-500 h-11'>
                        <h1 className='px-4 text-lg tracking-wide'>Let's Work</h1>
                        <div className='flex space-x-1'>
                            <IconBtn onClick={() => restartChat()} icon={<ArrowPathIcon className={`${isRefreshing ? 'animate-spin' : ''}`} />} />
                            <IconBtn onClick={() => setChatOpen(false)} icon={<XMarkIcon />} />
                        </div>
                    </div>
                    <ol ref={chatBoxRef} className='py-2 px-4 space-y-2 overflow-y-auto text-sm bg-dark-bg max-h-[60vh]' style={{ height: 'calc(100% - 8rem)' }}>
                        {chat?.messages &&
                            chat.messages.map((entry, idx) => (
                                <>
                                    {entry.role === 'assistant' && (
                                        <li key={'assistant-' + idx} className='flex items-center justify-start mr-8 h-min '>
                                            <p className='p-2 text-white rounded-lg bg-neutral-800 w-fit'>{entry.content}</p>
                                        </li>
                                    )}
                                    {entry.role === 'user' && (
                                        <li key={'user-' + idx} className='flex items-center justify-end ml-8 h-min '>
                                            <p className='p-2 border rounded-lg border-slate-500 bg-theme-mg w-fit'>{entry.content}</p>
                                        </li>
                                    )}
                                </>
                            ))}
                        {isPending && (
                            <>
                                <li className='flex items-center justify-end ml-8 h-min'>
                                    <p className='p-2 border rounded-lg border-slate-500 bg-theme-mg text-neutral-800 w-fit'>{message}</p>
                                </li>
                                <li className='flex items-center justify-start mr-8 h-min '>
                                    <p className='p-2 text-white rounded-lg bg-neutral-800 w-fit animate-pulse'> ... </p>
                                </li>
                            </>
                        )}
                    </ol>
                    <div className='flex items-start w-full px-4 pt-1 border-t h-[4.5rem]'>
                        <TextAreaField value={isPending ? '' : message} onChange={isPending ? undefined : (val) => setMessage(val)} className='flex-1' disabled={isPending} rows={3} onEnter={sendMessage} />
                        <span className='mt-1 ml-3'>
                            <IconBtn icon={<PaperAirplaneIcon />} onClick={sendMessage} />
                        </span>
                    </div>
                </section>
            ) : (
                <div className='fixed flex items-center justify-center w-12 h-12 text-white bg-neutral-800 rounded-full cursor-pointer sm:hidden bottom-4 right-4 z-[60]' onClick={() => setChatOpen(true)}>
                    <ChatBubbleLeftRightIcon className='w-8 h-8' />
                </div>
            )}
        </>
    )
}
