import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router-dom'

import { useHeights } from '../context'
import logo from '../logo.png'
import ImageViewer from './image-viewer'
import ThemeButton from './theme-button'

export default function AppHeader({ showSidebar, setShowSidebar }) {
    const navigate = useNavigate()
    const { userData } = useHeights()

    return (
        <header>
            {/* Wide / Desktop */}
            <div className='items-center justify-end hidden py-2 pr-8 space-x-4 border-b sm:flex bg-dark-bg border-neutral-800'>
                <section className='divide-y divide-neutral-400'>
                    <div className='flex items-center space-x-4 overflow-hidden cursor-pointer' onClick={() => navigate(`/profile`)}>
                        <span className='text-lg text-neutral-800'>{userData?.name?.split(' ')[0] ?? ''}</span>
                        {userData?.image && (
                            <div className='relative w-8 h-8'>
                                {/* <div className='absolute w-16 h-16 rounded-full opacity-50 top-[.3rem] left-[.3rem] bg-emerald-600' /> */}
                                <ImageViewer xs='true' image={userData.image} className='absolute top-0 left-0 object-cover w-8 h-8 border border-black rounded-full shadow' />
                            </div>
                        )}
                    </div>
                </section>
                <nav className='flex items-center'>
                    <ThemeButton onClick={() => navigate('/logout')}>logout</ThemeButton>
                </nav>
            </div>

            {/* Narrow / Mobile */}
            <div className='relative z-40 flex items-center justify-center border-b select-none border-neutral-800 bg-dark-bg text-neutral-800 h-14 sm:hidden'>
                <button className='absolute top-0 flex items-center h-14 left-4'>{showSidebar ? <XMarkIcon className='w-8 h-8 cursor-pointer' onClick={() => setShowSidebar(false)} /> : <Bars3Icon className='w-8 h-8 cursor-pointer' onClick={() => setShowSidebar(true)} />}</button>
                <figure className='flex items-center h-full px-4 cursor-pointer' onClick={() => navigate('/')}>
                    <img src={logo} className='h-8' alt='heights'></img>
                    {/* <span className='text-3xl font-light tracking-wide mono-font min-w-fit'>heights</span> */}
                </figure>
            </div>
        </header>
    )
}
