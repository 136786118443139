import React, { useEffect, useState } from 'react'

import { api } from './services/api.service'

const HeightsContext = React.createContext()
const { Provider } = HeightsContext

export const HeightsProvider = ({ children }) => {
    const [sidebarChildren, setSidebarChildren] = useState(null)
    const [isChatOpen, setChatOpen] = useState(false)
    const [activityData, setActivity] = useState([])
    const [nutritionData, setNutrition] = useState([])
    const [userData, setUser] = useState({})
    const [goalsData, setGoals] = useState([])
    const [observationData, setObservationData] = useState({})

    useEffect(() => {
        getObservations()
        reload()
    }, [])

    function updateActivity(updated) {
        api(`${process.env.REACT_APP_API_URL}/app/activity`, { data: updated }).then(api(`${process.env.REACT_APP_API_URL}/app/activity`).then((x) => setActivity(x)))
    }

    function updateNutrition(updated) {
        api(`${process.env.REACT_APP_API_URL}/app/nutrition`, { data: updated }).then(api(`${process.env.REACT_APP_API_URL}/app/nutrition`).then((x) => setNutrition(x)))
    }

    function updateUser(updated) {
        api(`${process.env.REACT_APP_API_URL}/app/settings`, { data: updated }).then(
            api(`${process.env.REACT_APP_API_URL}/app/settings`).then((x) => {
                setUser(x)
            })
        )
    }

    function updateGoals(updated) {
        api(`${process.env.REACT_APP_API_URL}/app/goals`, { data: updated }).then(api(`${process.env.REACT_APP_API_URL}/app/goals`).then((x) => setGoals(x)))
    }

    function reload() {
        api(`${process.env.REACT_APP_API_URL}/app/activity`).then((x) => {
            // console.log('ping')
            setActivity(x)
        })
        api(`${process.env.REACT_APP_API_URL}/app/nutrition`).then((x) => {
            // console.log('ping')
            setNutrition(x)
        })
        api(`${process.env.REACT_APP_API_URL}/app/settings`).then((x) => {
            // console.log('ping')
            setUser(x)
        })
        api(`${process.env.REACT_APP_API_URL}/app/goal`).then((x) => {
            // console.log('ping')
            setGoals(x)
        })
    }

    function getObservations() {
        api(`${process.env.REACT_APP_API_URL}/chat/observe-activity`).then((x) => {
            try {
                let resultObject = JSON.parse(x);
                setObservationData((p) => ({ ...p, activity: resultObject }))
            }
            catch (e) {console.log(e)}
        })
        api(`${process.env.REACT_APP_API_URL}/chat/observe-nutrients`).then((x) => {
            try {
                let resultObject = JSON.parse(x);
                setObservationData((p) => ({ ...p, nutrients: resultObject }))
            }
            catch (e) {console.log(e)}
        })
        api(`${process.env.REACT_APP_API_URL}/chat/observe-calories`).then((x) => {
            try {
                let resultObject = JSON.parse(x);
                setObservationData((p) => ({ ...p, calories: resultObject }))
            }
            catch (e) {console.log(e)}
        })
    }

    return <Provider value={{ sidebarChildren, setSidebarChildren, isChatOpen, setChatOpen, activityData, nutritionData, userData, goalsData, observationData, updateActivity, updateGoals, updateNutrition, updateUser, reload, getObservations }}>{children}</Provider>
}

export const useHeights = () => React.useContext(HeightsContext)
