import { useNavigate, useParams } from 'react-router-dom'

import PageHeader from '../components/page-header'

import { DateField, NumberField, PasswordField, TextField } from './fields'
import FormWrapper from './form-wrapper'

export default function UserForm(props) {
    const params = useParams()
    const navigate = useNavigate()
    let id = params.id ?? props.id

    return (
        <div>
            <PageHeader headline='Manage User'></PageHeader>
            <FormWrapper url='admin/user' id={id} {...props} callback={() => navigate(-1)}>
                {(values, setValues) => (
                    <>
                        <TextField label='Name' value={values.name} onChange={(val) => setValues({ ...values, name: val })} />
                        <TextField label='Email' value={values.email} onChange={(val) => setValues({ ...values, email: val })} />


                        <DateField label='Birthday' value={values.birthday} onChange={(val) => setValues({ ...values, birthday: val })} />
                        <TextField label='Sex' value={values.sex} onChange={(val) => setValues({ ...values, sex: val })} />
                        <NumberField label='Weight' value={values.weight} onChange={(val) => setValues({ ...values, weight: val })} />
                        <NumberField label='Height' value={values.height} onChange={(val) => setValues({ ...values, height: val })} />
                        <NumberField label='CalorieTarget' value={values.calorieTarget} onChange={(val) => setValues({ ...values, calorieTarget: val })} />
                        <TextField label='CoachPersona' value={values.coachPersona} onChange={(val) => setValues({ ...values, coachPersona: val })} />

                        <hr className="my-5" />
                        <PasswordField autoComplete="off" label='New Password' value={values.password} onChange={(val) => setValues({ ...values, password: val })}></PasswordField>
                        <div>Leave blank unless you want to update this users password</div>
                    </>
                )}
            </FormWrapper>
        </div >
    )
}
