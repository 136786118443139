import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'

export default function FormModal({ open, setOpen, children, title }) {
    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as='div' className='relative z-[60] overflow-visible' onClose={setOpen}>
                <Transition.Child as={Fragment} enter='ease-out duration-300' enterFrom='opacity-0' enterTo='opacity-100' leave='ease-in duration-200' leaveFrom='opacity-100' leaveTo='opacity-0'>
                    <div className='fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75' />
                </Transition.Child>

                <div className='fixed inset-0 z-[60] overflow-y-auto'>
                    <div className='flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0'>
                        <Transition.Child as={Fragment} enter='ease-out duration-300' enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95' enterTo='opacity-100 translate-y-0 sm:scale-100' leave='ease-in duration-200' leaveFrom='opacity-100 translate-y-0 sm:scale-100' leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
                            <Dialog.Panel>{children}</Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
