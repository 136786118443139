import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid'
import React, { useEffect, useState } from 'react'

export function useLogger(value, name) {
    const [state, setState] = useState()

    useEffect(() => {
        setState(value)
    }, [value])

    useEffect(() => {
        console.log(`${name ? `${name}: ` : ''}`, state)
    }, [state])
}

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = { hasError: false, componentName: props.componentName }
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error: error }
    }

    componentDidCatch(error, info) {
        // Example "componentStack":
        //   in ComponentThatThrows (created by App)
        //   in ErrorBoundary (created by App)
        //   in div (created by App)
        //   in App
        //   logErrorToMyService(error, info.componentStack);
        // console.error(error)
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <FallbackUI {...this.state} />
        }

        return this.props.children
    }
}

function FallbackUI({ error, resetErrorBoundary, componentName = '' }) {
    const [showStack, setShowStack] = useState(false)

    return (
        <div className='p-2 m-2 bg-white rounded ring-2 ring-amber-600'>
            <h1 className='p-2 mb-2 text-lg font-semibold border-b border-amber-600'>{`${error?.name ?? 'Error'} ${componentName !== '' ? `inside ${componentName}` : ''}`}</h1>
            <div className='p-2'>
                <p className='mb-4'>
                    <b>Message: </b>
                    {error?.message ?? ''}
                </p>
                {showStack ? (
                    <div className='flex mb-2 font-semibold' onClick={() => setShowStack(false)}>
                        Hide Stack
                        <ChevronUpIcon className='w-6 h-6' />
                    </div>
                ) : (
                    <div className='flex mb-2 font-semibold' onClick={() => setShowStack(true)}>
                        Show Stack
                        <ChevronDownIcon className='w-6 h-6' />
                    </div>
                )}
                {showStack && <ul>{error?.stack && error.stack.split(' at').map((d, i) => <li key={i}>{`${i > 0 ? ' at ' : ''}${d}`}</li>)}</ul>}
            </div>
        </div>
    )
}
