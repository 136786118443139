import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { api } from '../services/api.service'

import Card from '../components/card'
import Loading from '../components/loading'
import ThemeButton from '../components/theme-button'

import { DateField, TextAreaField, TextField } from './fields'
import FormModal from './form-modal'
import FormWrapper from './form-wrapper'

export default function GoalForm(props) {
    const params = useParams()
    let id = params.id ?? props.id

    return (
        <Card header='Set a Goal'>
            <div className='m-4'>
                <GoalFormBody id={id} {...props} />
            </div>
        </Card>
    )
}

export function GoalFormBody(props) {
    const [goal, setGoal] = useState()
    const [screen, setScreen] = useState(1)
    const [chat, setChat] = useState({
        benchmarks: '',
        timing: '',
        whatToExpect: '',
        activityGuidance: '',
        nutritionGuidance: '',
        didYouKnow: '',
    })
    const [isLoading, setLoading] = useState(false)

    useEffect(() => {
        if (goal) {
            setLoading(true)
            api(`${process.env.REACT_APP_API_URL}/chat/goal-advice`, { newGoal: goal }).then((x) => {
                console.log(x, JSON.parse(x))
                setChat({ ...JSON.parse(x) })
                setLoading(false)
            })
        }
    }, [goal])

    return (
        <>
            <FormWrapper url='app/goal' noStyle={true} id={props.id} hideButton {...props}>
                {(values, setValues, submit) => (
                    <>
                        <div className='flex flex-col space-y-2'>
                            <TextField placeholder='What do you want to accomplish...' value={values.goal} onChange={(val) => setValues({ ...values, goal: val })} />
                            <div className='flex flex-row items-center flex-1 my-2 space-x-4'>
                                <span className='text-sm text-neutral-600'>Goal type?</span>
                                <button
                                    className={`rounded-full border border-stone-700 hover-shadow px-3 py-px ${values.category === 'strength' ? 'bg-blue-600 text-white hover:bg-blue-400' : 'text-blue-500 bg-white'}`}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setValues({ ...values, category: 'strength' })
                                    }}
                                >
                                    Strength
                                </button>
                                <button
                                    className={`rounded-full border border-stone-700 hover-shadow px-3 py-px ${values.category === 'cardio' ? 'bg-blue-600 text-white hover:bg-blue-400' : 'text-blue-500 bg-white'}`}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setValues({ ...values, category: 'cardio' })
                                    }}
                                >
                                    Cardio
                                </button>
                                <button
                                    className={`rounded-full border border-stone-700 hover-shadow px-3 py-px ${values.category === 'weight' ? 'bg-blue-600 text-white hover:bg-blue-400' : 'text-blue-500 bg-white'}`}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setValues({ ...values, category: 'weight' })
                                    }}
                                >
                                    Weight
                                </button>
                            </div>
                            <TextField placeholder='Where are you on that journey today...' value={values.current} onChange={(val) => setValues({ ...values, current: val })} />
                            <DateField label='Target Goal Date' value={values.goalDate} onChange={(val) => setValues({ ...values, goalDate: val })} />
                            <TextAreaField placeholder='anything else to note...' value={values.notes} onChange={(val) => setValues({ ...values, notes: val })} />
                            <div className='flex justify-end flex-1'>
                                <ThemeButton
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setGoal(values)
                                        setScreen(2)
                                    }}
                                >
                                    Save
                                </ThemeButton>
                            </div>
                        </div>
                        {screen === 2 && (
                            <ExpectationsModal
                                chat={chat}
                                isLoading={isLoading}
                                onNext={() => setScreen(3)}
                                onBack={() => {
                                    setScreen(1)
                                    setChat({
                                        benchmarks: '',
                                        timing: '',
                                        whatToExpect: '',
                                        activityGuidance: '',
                                        nutritionGuidance: '',
                                        didYouKnow: '',
                                    })
                                }}
                            />
                        )}
                        {screen === 3 && (
                            <HowToModal
                                chat={chat}
                                onNext={submit}
                                onBack={() => {
                                    setScreen(1)
                                    setChat({
                                        benchmarks: '',
                                        timing: '',
                                        whatToExpect: '',
                                        activityGuidance: '',
                                        nutritionGuidance: '',
                                        didYouKnow: '',
                                    })
                                }}
                            />
                        )}
                    </>
                )}
            </FormWrapper>
        </>
    )
}

const SUSPENSE_TEXT = ['Analyzing your new goal...', 'Generating useful insights...']

// SCREEN 2
function ExpectationsModal({ chat, isLoading, onNext, onBack }) {
    return (
        <FormModal open={true} setOpen={() => true}>
            <Card
                header={
                    <div className='flex flex-row items-center justify-between flex-1 space-x-4 text-sm'>
                        <h1>What to expect</h1>
                        <div className='space-x-2'>
                            <ThemeButton
                                onClick={(e) => {
                                    e.preventDefault()
                                    onNext()
                                }}
                            >
                                next
                            </ThemeButton>
                            <ThemeButton
                                onClick={(e) => {
                                    e.preventDefault()
                                    onBack()
                                }}
                            >
                                change goal
                            </ThemeButton>
                        </div>
                    </div>
                }
                className='max-w-lg text-sm'
            >
                {isLoading ? (
                    <div className='flex flex-col items-center justify-center m-8 space-y-4'>
                        <Loading height={16}></Loading>
                        <h2>{SUSPENSE_TEXT[Math.floor(Math.random() * SUSPENSE_TEXT.length)]}</h2>
                    </div>
                ) : (
                    <div className='m-4 divide-y divide-dotted divide-slate-500'>
                        <div className='grid items-start grid-cols-4 pt-2 mb-2 gap-x-4'>
                            <h2 className='font-bold text-left'>Activity guidance</h2>
                            <p className='col-span-3 text-left text-small'>{chat.activityGuidance}</p>
                        </div>
                        <div className='grid items-start grid-cols-4 pt-2 mb-2 gap-x-4'>
                            <h2 className='font-bold text-left'>Nutrition guidance</h2>
                            <p className='col-span-3 text-left text-small'>{chat.nutritionGuidance}</p>
                        </div>
                        <div className='grid items-start grid-cols-4 pt-2 mb-2 gap-x-4'>
                            <h2 className='font-bold text-left'>Did you know?</h2>
                            <p className='col-span-3 text-left text-small'>{chat.didYouKnow}</p>
                        </div>
                    </div>
                )}
            </Card>
        </FormModal>
    )
}

// SCREEN 3
function HowToModal({ chat, onNext, onBack }) {
    return (
        <FormModal open={true} setOpen={() => true}>
            <Card
                header={
                    <div className='flex flex-row items-center justify-between flex-1 space-x-4 text-sm'>
                        <h1>How you'll do it</h1>
                        <div className='space-x-2'>
                            <ThemeButton
                                onClick={(e) => {
                                    e.preventDefault()
                                    onNext()
                                }}
                            >
                                ok, great!
                            </ThemeButton>
                            <ThemeButton
                                onClick={(e) => {
                                    e.preventDefault()
                                    onBack()
                                }}
                            >
                                change goal
                            </ThemeButton>
                        </div>
                    </div>
                }
                className='max-w-lg text-sm'
            >
                <div className='m-4 divide-y divide-dotted divide-slate-500'>
                    <div className='grid items-start grid-cols-4 pt-2 mb-2 gap-x-4'>
                        <h2 className='font-bold text-left'>Benchmarks</h2>
                        <p className='col-span-3 text-left text-small'>{chat.benchmarks}</p>
                    </div>
                    <div className='grid items-start grid-cols-4 pt-2 mb-2 gap-x-4'>
                        <h2 className='font-bold text-left'>Timing</h2>
                        <p className='col-span-3 text-left text-small'>{chat.timing}</p>
                    </div>
                    <div className='grid items-start grid-cols-4 pt-2 mb-2 gap-x-4'>
                        <h2 className='font-bold text-left'>What to expect</h2>
                        <p className='col-span-3 text-left text-small'>{chat.whatToExpect}</p>
                    </div>
                </div>
            </Card>
        </FormModal>
    )
}
