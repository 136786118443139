import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'

export default function PExpand({ children, className }) {
    const [open, setOpen] = useState(false)

    return (
        <div className={`flex flex-col`}>
            <p className={`flex-1 ${open ? '' : 'line-clamp-3'} ${className}`}>{children}</p>
            <div className='flex flex-row items-center justify-center'>{open ? <ChevronUpIcon className='w-6 cursor-pointer text-neutral-800' onClick={() => setOpen(false)} /> : <ChevronDownIcon className='w-6 cursor-pointer text-neutral-800' onClick={() => setOpen(true)} />}</div>
        </div>
    )
}
