import moment from 'moment'
import { MinusCircleIcon, PencilIcon, PlusCircleIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { api, api_delete } from '../services/api.service'

import Card from '../components/card'
import Confirm from '../components/confirm'
import ErrorBoundary from '../components/error-boundary'
import Loading from '../components/loading'
import ThemeButton, { TickBtn, TrashBtn } from '../components/theme-button'
import { getCombinedFieldNames } from '../components/utils'

import { useHeights } from '../context'
import AddFieldBlock from './add-field-block'
import { DateField, NumberField, SelectField, TextAreaField, TextField } from './fields'
import FormWrapper from './form-wrapper'

export default function StrengthActivityForm(props) {
    const params = useParams()
    const navigate = useNavigate()

    const { activityData, updateActivity, userData, reload } = useHeights()

    const [id, setId] = useState(props.id ?? params.id)
    const [activity, setActivity] = useState()
    const [deleteId, setDeleteId] = useState()
    const [isPending, setPending] = useState(false)

    useEffect(() => {
        setActivity(activityData.find((d) => d._id === id))
    }, [userData, activityData])

    function confirmDeleteActivity() {
        api_delete(`${process.env.REACT_APP_API_URL}/app/activity/${deleteId}`).then(() => {
            setDeleteId(null)
            reload()
            if (params.id === deleteId) {
                navigate(-1)
            }
        })
    }

    function addNewField(field) {
        if (!field.name) {
            return
        }
        api(`${process.env.REACT_APP_API_URL}/app/settings/fields`, { data: { ...userData, customFields: [...(userData?.customFields ?? []), field] } }).then((x) => reload())
    }

    async function getCalorieEstimate(activity) {
        let response = await api(`${process.env.REACT_APP_API_URL}/chat/estimate-activity-calories`, { data: activity })
        let content = response.messages[response.messages.length - 1].content
        try {
            let estimate = JSON.parse(content)
            return estimate?.calories ?? 0
        } catch {
            console.info('[ PROMPT RETURN ] Calorie estimate error catch with response: ', content)
            alert(content)
        }
        return null
    }

    async function formCallback(e, data) {
        e.preventDefault()
        setPending(true)
        if (data.terraData) {
            data.totalCalories ||= data.terraData?.calories_data?.total_burned_calories || (data.terraData?.calories_data?.net_activity_calories ?? 0)
            await updateActivity({ ...data, terraData: undefined })
        } else {
            let estimate = await getCalorieEstimate(data)
            if (estimate) {
                data.totalCalories = estimate
            }
            await updateActivity(data)
        }
        reload()
        setPending(false)
    }

    if (!userData || (!activity && id !== 'new')) return <Loading></Loading>

    return (
        <>
            <Confirm open={!!deleteId} cancel={() => setDeleteId(null)} confirm={() => confirmDeleteActivity()} />
            <Card header={props.header ?? 'Strength Activity'}>
                <FormWrapper url='app/activity' id={id} callback={() => reload()} defaultValue={{ type: 'strength', exercises: [], totalCalories: 0, dateTime: new Date() }} noStyle className='m-4 mt-2' hideButton {...props}>
                    {(values, setValues) => (
                        <>
                            <div className='grid grid-cols-1 gap-2 sm:grid-cols-2'>
                                <TextField required value={values?.title ?? ''} label='TITLE' className='w-full' onChange={(val) => setValues({ ...values, title: val })} />
                                <DateField
                                    required
                                    label='DATE'
                                    value={values.dateTime}
                                    onChange={(val) => {
                                        setValues({ ...values, dateTime: moment(val).toDate() })
                                    }}
                                />
                            </div>
                            <TextAreaField value={values?.description ?? ''} label='DESCRIPTION' onChange={(val) => setValues({ ...values, description: val })} rows={3} />
                            <div className='grid grid-cols-1 gap-2 sm:grid-cols-2'>
                                <SelectField value={values?.feeling} label='FEELING' className='mt-2 h-[41.33px]' onChange={(val) => setValues({ ...values, feeling: val })} optionListName={'activity_feeling'} />
                                <NumberField className='mt-2' labelClass='mb-1' value={values?.totalCalories} label='CALORIES' onChange={(val) => setValues({ ...values, totalCalories: Number(val) })} />
                            </div>
                            <TextAreaField value={values?.comments ?? ''} label='COMMENTS' onChange={(val) => setValues({ ...values, comments: val })} rows={3} />

                            <div className='w-full my-3 border-b border-dotted border-slate-500' />

                            <ErrorBoundary componentName='ExerciseBlock'>
                                <div className='flex flex-col space-y-2'>
                                    {values.exercises.map((exercise, idx) => (
                                        <ExerciseBlock
                                            key={idx}
                                            exercise={exercise}
                                            fieldsList={getCombinedFieldNames(values, userData.customFields)}
                                            onChange={(updatedExercise) => setValues({ ...values, exercises: values.exercises.map((d, i) => (i === idx ? updatedExercise : d)) })}
                                            onDelete={() => {
                                                let nextState = { ...values, exercises: values.exercises.filter((_, i) => i !== idx) }
                                                console.log('SHOULD DELETE', nextState)
                                                id === 'new' ? setValues(nextState) : updateActivity(nextState)
                                            }}
                                        />
                                    ))}
                                </div>
                            </ErrorBoundary>

                            <NewExerciseBlock
                                onConfirm={(newExercise) => {
                                    let nextState = { ...values, exercises: [...(values.exercises ?? []), { name: newExercise, sets: [{ completed: false, fields: {} }] }] }
                                    id === 'new' ? setValues(nextState) : updateActivity(nextState)
                                }}
                            />

                            <AddFieldBlock fieldsList={getCombinedFieldNames(values, userData.customFields)} onConfirm={(newFieldName) => addNewField({ name: newFieldName, type: 'strength' })} />

                            <div className='flex items-center justify-end space-x-3'>
                                <ThemeButton onClick={(e) => formCallback(e, values)}>
                                    {isPending ? (
                                        <span className='flex items-center justify-center p-[2px]'>
                                            <Loading height={16} />
                                        </span>
                                    ) : (
                                        'Save'
                                    )}
                                </ThemeButton>
                                {id !== 'new' && (
                                    <TrashBtn
                                        onClick={(e) => {
                                            e.preventDefault()
                                            setDeleteId(id)
                                        }}
                                    />
                                )}
                            </div>
                        </>
                    )}
                </FormWrapper>
            </Card>
        </>
    )
}

const ExerciseBlock = ({ exercise, fieldsList, onChange, onDelete }) => {
    const [state, setState] = useState()
    const [nameEdit, setNameEdit] = useState({ isEditing: false, name: exercise.name })

    useEffect(() => {
        let _state = Object.assign({}, exercise)

        for (let _set of _state.sets) {
            if (!_set.fields) {
                _set.fields = {}
            }
            for (const fieldName of fieldsList) {
                if (!_set.fields.hasOwnProperty(fieldName)) {
                    _set.fields[fieldName] = undefined
                }
            }
        }

        setState(_state)
        setNameEdit({ ...nameEdit, name: _state.name })
    }, [exercise, fieldsList])

    if (!state) return <></>

    return (
        <div className='flex flex-col w-full'>
            <div className='flex items-center space-x-4'>
                {nameEdit.isEditing ? (
                    <>
                        <TextField
                            value={nameEdit.name}
                            onChange={(val) => {
                                setNameEdit({ ...nameEdit, name: val })
                            }}
                        />
                        <TickBtn
                            onClick={() => {
                                onChange({ ...state, name: nameEdit.name })
                                setNameEdit({ ...nameEdit, isEditing: false })
                            }}
                        />
                    </>
                ) : (
                    <>
                        <h3>{state.name}</h3>
                        <PencilIcon className='w-5 h-5 cursor-pointer text-slate-700 hover:text-slate-500' onClick={() => setNameEdit({ ...nameEdit, isEditing: true })} />
                    </>
                )}
            </div>
            <div className='flex flex-col my-2 mb-4 space-y-2'>
                {/* Header Row */}
                {state.sets.length > 0 && (
                    <div className='flex space-x-2'>
                        <div className='w-8'>SET</div>
                        {fieldsList.map((fieldName, idx) => (
                            <div key={fieldName + idx} className='flex-1 text-center'>
                                {fieldName.split('_').join(' ').toUpperCase()}
                            </div>
                        ))}
                        <div className='w-[7ch] text-center'>+/- 5lbs</div>
                        <div className='w-6'></div>
                    </div>
                )}
                {/* Data Rows */}
                {state.sets.map((setObj, setIdx) => (
                    <div key={setIdx} className='flex items-center space-x-2'>
                        <div className='w-8 text-center text-white bg-neutral-800'>{setIdx + 1}</div>

                        {/* Field Values */}
                        {fieldsList.map((fieldName) => (
                            <div key={fieldName} className='flex-1 h-10 text-center'>
                                <input
                                    type={typeof setObj.fields[fieldName] === 'number' ? 'number' : 'text'}
                                    value={setObj.fields[fieldName]}
                                    className='w-full h-10 text-center border border-dashed rounded border-neutral-300'
                                    onFocus={(e) => e.target.select()}
                                    onChange={(e) => {
                                        if (typeof setObj.fields[fieldName] === 'number') {
                                            setObj.fields[fieldName] = e.target.valueAsNumber
                                        } else {
                                            setObj.fields[fieldName] = e.target.value
                                        }
                                        onChange({
                                            ...state,
                                            sets: state.sets.map((x, i) => (i === setIdx ? setObj : x)),
                                        })
                                    }}
                                />
                            </div>
                        ))}
                        <div className='flex items-center justify-center space-x-2 w-[7ch]'>
                            <PlusCircleIcon className='w-5 h-5 transition cursor-pointer hover:bg-neutral-300/50' onClick={() => onChange({ ...state, sets: state.sets.map((d, i) => (i === setIdx ? { ...setObj, fields: { ...setObj.fields, weight: (Number(setObj.fields?.weight) ?? 0) + 5 } } : d)) })} />
                            <MinusCircleIcon
                                className='w-5 h-5 transition cursor-pointer hover:bg-neutral-300/50'
                                onClick={() => {
                                    onChange({
                                        ...state,
                                        sets: state.sets.map((d, i) => {
                                            if (i === setIdx) {
                                                let value = (Number(setObj.fields?.weight) ?? 0) - 5
                                                value = value < 0 ? 0 : value
                                                return { ...setObj, fields: { ...setObj.fields, weight: value } }
                                            }
                                            return d
                                        }),
                                    })
                                }}
                            />
                        </div>

                        <TrashBtn
                            onClick={(e) => {
                                e.preventDefault()
                                state.sets.length <= 1
                                    ? onDelete()
                                    : onChange({
                                          ...state,
                                          sets: state.sets.filter((_, i) => i !== setIdx),
                                      })
                            }}
                        />
                    </div>
                ))}
                {/* Add New Set */}
                <ThemeButton
                    className='flex items-center justify-center text-blue-600 cursor-pointer w-min hover:text-blue-700'
                    onClick={(e) => {
                        e.preventDefault()

                        let newSet
                        if (state.sets?.length) {
                            let last = state.sets[state.sets.length - 1]
                            newSet = {
                                completed: last.completed,
                                fields: Object.assign({}, last.fields),
                            }
                        } else {
                            let _fields = {}
                            for (const f of fieldsList) {
                                _fields[f] = undefined
                            }
                            newSet = { completed: false, fields: _fields }
                        }

                        onChange({
                            ...state,
                            sets: state.sets.concat(newSet),
                        })
                    }}
                >
                    +SET
                </ThemeButton>
            </div>
        </div>
    )
}

const NewExerciseBlock = ({ onConfirm }) => {
    const [isAdding, setAdding] = useState(false)
    const [name, setName] = useState('')

    return (
        <div className='flex my-2'>
            {isAdding ? (
                <div className='flex items-end mb-2 space-x-2'>
                    <TextField value={name} onChange={(val) => setName(val)} label='EXERCISE NAME' className='w-fit' />
                    <ThemeButton
                        onClick={(e) => {
                            e.preventDefault()
                            onConfirm(name)
                            setName('')
                            setAdding(false)
                        }}
                        disabled={name.trim() === ''}
                        className='mb-2 disabled:opacity-40'
                    >
                        Confirm
                    </ThemeButton>
                    <ThemeButton
                        className='mb-2'
                        onClick={(e) => {
                            e.preventDefault()
                            setName('')
                            setAdding(false)
                        }}
                    >
                        Cancel
                    </ThemeButton>
                </div>
            ) : (
                <ThemeButton
                    onClick={(e) => {
                        e.preventDefault()
                        setAdding(true)
                        setName('')
                    }}
                >
                    +EXERCISE
                </ThemeButton>
            )}
        </div>
    )
}
