import moment from 'moment'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import Card from '../components/card'
import ErrorBoundary from '../components/error-boundary'
import { AppStickyPageHeader } from '../components/page-header'
import { ArrowBtn } from '../components/theme-button'

import { useHeights } from '../context'

export default function DayView() {
    const params = useParams()
    const navigate = useNavigate()
    const [activities, setActivities] = useState([])
    const [nutrition, setNutrition] = useState(null)
    const { setSidebarChildren, activityData, nutritionData, userData } = useHeights()

    useEffect(() => {
        let _dt = moment(params.date)
        setActivities(activityData.filter(({ dateTime }) => moment(dateTime).isSame(_dt, 'date')))
        setNutrition(nutritionData.find(({ dateTime }) => moment(dateTime).isSame(_dt, 'date')))
    }, [activityData, nutritionData, params])

    useEffect(() => {
        if (nutrition && activities && userData) {
            setSidebarChildren(<CalorieUse nutrition={nutrition} activities={activities} userDailyCalories={nutrition?.burnedCalories ?? 0} />)
        }
    }, [nutrition, activities, userData])

    return (
        <ErrorBoundary componentName='DayView children'>
            <>
                <AppStickyPageHeader onBack={() => navigate(-1)}></AppStickyPageHeader>

                <Card header={moment(params.date).format('MMMM Do YYYY')}>
                    <div className='flex flex-col px-1 pt-5 pb-16 sm:px-6'>
                        <section>
                            <h1 className='px-2 py-1 text-2xl border-b border-slate-500'>activities</h1>
                            <ul className='divide-y divide-slate-700 divide-dotted'>
                                {nutrition?.burnedCalories ? (
                                    <li className='grid items-center grid-cols-10 px-4 transition h-11'>
                                        <span className='text-lg'>{Math.round(nutrition.burnedCalories ?? 0)}</span>
                                        <span className='col-span-8'>BMR Calories</span>
                                    </li>
                                ) : (
                                    <></>
                                )}
                                {activities.map((x) => (
                                    <li key={x.title} className='grid items-center grid-cols-10 px-4 transition cursor-pointer h-11 hover:bg-neutral-300/50' onClick={() => navigate(`/activity/${x._id ?? 'new'}`)}>
                                        <span className='text-lg'>{Math.round(x?.totalCalories ?? 0)}</span>
                                        <span className='col-span-8'>{x.title}</span>
                                        <ArrowBtn parentHover />
                                    </li>
                                ))}
                            </ul>
                        </section>
                        <section className='mt-6'>
                            <h1 className='px-2 py-1 text-2xl border-b border-slate-500'>meals</h1>
                            <ul className='divide-y divide-slate-700 divide-dotted'>
                                {nutrition?.meals?.map((x) => (
                                    <li key={x.title} className='grid items-center grid-cols-10 px-4 transition cursor-pointer h-11 hover:bg-neutral-300/50' onClick={() => navigate(`/nutrition/${moment(params.date).format('YYYY-MM-DD')}`)}>
                                        <span className='text-lg'>{Math.round(x?.calories ?? 0)}</span>
                                        <span className='col-span-8'>{x.title}</span>
                                        <ArrowBtn parentHover />
                                    </li>
                                ))}
                            </ul>
                        </section>
                    </div>
                </Card>
            </>
        </ErrorBoundary>
    )
}

/*
    Right Sidebar Content
*/
const CalorieUse = ({ className, nutrition, activities, userDailyCalories }) => {
    const [consumed, setConsumed] = useState(0)
    const [used, setUsed] = useState(0)

    useEffect(() => {
        let _consumed = nutrition?.meals?.reduce((tot, c) => (tot += c?.calories ?? 0), 0) ?? 0
        setConsumed(Math.round(_consumed))
        let _used = activities.reduce((tot, d) => (tot += d?.totalCalories ?? 0), 0)
        setUsed(Math.round(_used))
    }, [nutrition, activities])

    return (
        <div className={`flex flex-col  items-stretch ${className}`}>
            <div className='p-2 text-lg tracking-wide border-b border-neutral-800'>calorie use</div>
            <div className='grid grid-cols-5 px-4'>
                <div className='col-span-2 py-3 text-xl border-b border-dotted align-center border-slate-500'>{Math.abs(consumed - (used + userDailyCalories))}</div>
                <div className='col-span-3 py-3 text-lg border-b border-dotted align-center border-slate-500'>{consumed - (used + userDailyCalories) > 0 ? 'surplus' : 'deficit'}</div>

                <div className='col-span-2 py-3 border-b border-dotted align-center border-slate-500'>{consumed}</div>
                <div className='col-span-3 py-3 text-sm border-b border-dotted align-center border-slate-500'>consumed</div>

                <div className='col-span-2 py-3 border-b border-dotted align-center border-slate-500'>{used}</div>
                <div className='col-span-3 py-3 text-sm border-b border-dotted align-center border-slate-500'>activity</div>

                <div className='col-span-2 py-3 align-center'>{userDailyCalories}</div>
                <div className='col-span-3 py-3 text-sm align-center'>maintenance</div>
            </div>
        </div>
    )
}
