import { TrashIcon } from '@heroicons/react/24/outline'

export default function ThemeButton(props) {
    return (
        <button {...props} className={`rounded-full border border-stone-700 text-blue-600 bg-white hover-shadow px-3 py-px ${props?.className ?? ''}`}>
            {props.children}
        </button>
    )
}

export function ArrowBtn({ onClick, flip = false, parentHover = false }) {
    return (
        <button onClick={onClick} className={`px-1 py-1 text-blue-600 bg-white border rounded-full border-slate-500 hover-shadow w-min ${parentHover ? 'btn-parent-hover' : ''}`}>
            <svg viewBox='0 0 16 16' fill='none' height='12px'>
                <g transform={flip ? 'rotate(180 8 8)' : undefined}>
                    <path d='M0 8 h16' stroke='green' />
                    <path d='M16 8, Q10 6, 8 0' stroke='green' />
                    <path d='M16 8, Q10 10, 8 16' stroke='green' />
                </g>
            </svg>
        </button>
    )
}

export function TickBtn({ onClick }) {
    return (
        <button onClick={onClick} className='px-2 py-1 border rounded-full border-stone-700 hover-shadow w-min bg-emerald-600 hover:bg-emerald-800'>
            <svg viewBox='0 0 16 16' fill='none' height='12px'>
                <path d='M1 7, 7 14, 16 1' stroke='white' strokeWidth='2px' />
            </svg>
        </button>
    )
}

export function IconBtn({ icon, onClick }) {
    return (
        <button onClick={onClick} className='w-6 h-6 p-1 border rounded-full border-slate-500 hover-shadow'>
            {icon}
        </button>
    )
}

export function TrashBtn({ onClick }) {
    return (
        <button onClick={onClick} className='px-0 transition bg-transparent text-stone-700 hover:text-rose-600'>
            <TrashIcon className='w-5 h-5' />
        </button>
    )
}
