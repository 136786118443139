import moment from 'moment/moment'
import { Bar, BarChart, Cell, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { useEffect, useState } from 'react'

import { useHeights } from '../context'
import { getBMR } from './utils'

const MOCK = JSON.parse(
    '[{"date":"30/08","Net calories":200,"Protein":-20,"Carbs":-10},{"date":"31/08","Net calories":112,"Protein":3,"Carbs":-25},{"date":"01/09","Net calories":175,"Protein":-5,"Carbs":14},{"date":"02/09","Net calories":200,"Protein":5,"Carbs":-8},{"date":"03/09","Net calories":-80,"Protein":10,"Carbs":10},{"date":"04/09","Net calories":115,"Protein":8,"Carbs":5},{"date":"05/09","Net calories":350,"Protein":-8,"Carbs":-4},{"date":"06/09","Net calories":20,"Protein":16,"Carbs":-8},{"date":"07/09","Net calories":100,"Protein":-5,"Carbs":2},{"date":"08/09","Net calories":240,"Protein":9,"Carbs":9},{"date":"09/09","Net calories":40,"Protein":-5,"Carbs":10},{"date":"10/09","Net calories":373,"Protein":0,"Carbs":-17},{"date":"11/09","Net calories":126,"Protein":-10,"Carbs":3},{"date":"12/09","Net calories":-55,"Protein":-4,"Carbs":-9}]'
)

export default function ChartNutrition() {
    const [data, setData] = useState()
    const [benchmarks, setBenchmarks] = useState({ protein: 0, carbs: 0 })

    const { nutritionData, activityData, userData, goalsData } = useHeights()

    useEffect(() => {
        console.log(goalsData)
        let _benchmarks = getBenchmarks(userData, goalsData)
        setBenchmarks(_benchmarks)
        let _data = getChartData(nutritionData, activityData, userData, _benchmarks)
        setData(_data)
        // setData(MOCK)
    }, [nutritionData, activityData, userData, goalsData])

    if (!data) return <></>

    return (
        <div className='grid items-center grid-cols-6'>
            <ResponsiveContainer width='100%' height={100} className='col-span-5'>
                <BarChart width={500} height={100} data={data} margin={{ top: 20, bottom: 20, right: 20 }}>
                    <Tooltip content={<CustomTooltip />} />
                    <XAxis dataKey='date' hide />
                    <YAxis ticks={[-500, 500]} tickFormatter={(v) => `${v > 0 ? '+' : ''}${v}`} />
                    <ReferenceLine y={0} stroke='#666' />
                    <Bar dataKey='Net calories' stroke='#64748b'>
                        {data.map((entry, index) => (
                            <Cell key={index} fill={entry['Net calories'] > 0 ? '#0ea5e9' : '#f59e0b'} />
                        ))}
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
            <h1 className='text-sm'>Net calories</h1>
            <ResponsiveContainer width='100%' height={100} className='col-span-5'>
                <BarChart width={500} height={100} data={data} margin={{ top: 20, bottom: 20, right: 20 }}>
                    <Tooltip content={<CustomTooltip />} />
                    <XAxis dataKey='date' hide />
                    <YAxis ticks={[-30, 30]} tickFormatter={(v) => `${v > 0 ? '+' : ''}${v}`} />
                    <ReferenceLine y={0} stroke='#666' />
                    <Bar dataKey='Protein delta' stroke='#64748b'>
                        {data.map((entry, index) => (
                            <Cell key={index} fill={entry['Protein delta'] > 0 ? '#0ea5e9' : '#f59e0b'} />
                        ))}
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
            <h1 className='flex flex-col text-sm'>
                Protein <span>{`(target: ${benchmarks.protein}g)`}</span>
            </h1>
            <ResponsiveContainer width='100%' height={100} className='col-span-5'>
                <BarChart width={500} height={100} data={data} margin={{ top: 20, bottom: 20, right: 20 }}>
                    <Tooltip content={<CustomTooltip />} />
                    <XAxis dataKey='date' hide />
                    <YAxis ticks={[-30, 30]} tickFormatter={(v) => `${v > 0 ? '+' : ''}${v}`} />
                    <ReferenceLine y={0} stroke='#666' />
                    <Bar dataKey='Carbs delta' stroke='#64748b'>
                        {data.map((entry, index) => (
                            <Cell key={index} fill={entry['Carbs delta'] > 0 ? '#0ea5e9' : '#f59e0b'} />
                        ))}
                    </Bar>
                </BarChart>
            </ResponsiveContainer>{' '}
            <h1 className='flex flex-col text-sm'>
                Carbs <span>{`(target: ${benchmarks.carbs}g)`}</span>
            </h1>
        </div>
    )
}

const BENCHMARK_DEFAULTS = {
    strengthGoal: {
        protein: 130,
        carbs: 200,
    },
    otherGoal: {
        protein: 90,
        carbs: 220,
    },
    noGoal: {
        protein: 70,
        carbs: 210,
    },
}

function getBenchmarks(user, goals) {
    let benchmark = {}

    console.log('[ CHART BENCHMARKS ] ')

    let _goals = goals?.filter(({ goalDate, isComplete }) => !isComplete && moment(goalDate).isAfter(moment())) ?? null

    console.log('USER ACTIVE + NOT-COMPLETED GOALS: ', _goals)

    const currentGoal = _goals?.length ? _goals.sort((a, b) => (moment(a.goalDate).isSameOrAfter(b.goalDate) ? 1 : -1))[0] : null

    console.log('CURRENT GOAL (nearest completion date):', currentGoal)

    if (user.weight) {
        console.log('user HAS set weight')
        if (currentGoal) {
            if (currentGoal.category === 'strength') {
                console.log('user HAS set a STRENGTH goal -> benchmark set to weight-adjusted values: protein @ 0.9g per lb , carbs @ 1.5g per lb')
                benchmark['protein'] = Math.round(user.weight * 2.2 * 0.9)
                benchmark['carbs'] = Math.round(user.weight * 2.2 * 1.5)
            } else {
                console.log('user HAS set a NON-STRENGTH goal -> benchmark set to weight-adjusted values: protein @ 0.75g per lb , carbs @ 1.75g per lb')
                benchmark['protein'] = Math.round(user.weight * 2.2 * 0.75)
                benchmark['carbs'] = Math.round(user.weight * 2.2 * 1.75)
            }
        } else {
            console.log('user HAS NOT set a goal -> benchmark set to weight-adjusted values: protein @ 0.6g per lb , carbs @ 1.25g per lb')
            benchmark['protein'] = Math.round(user.weight * 2.2 * 0.6)
            benchmark['carbs'] = Math.round(user.weight * 2.2 * 1.25)
        }
    } else {
        console.log('user HAS NOT set weight')
        if (currentGoal) {
            if (currentGoal.category === 'strength') {
                console.log('user HAS set a STRENGTH goal -> benchmark set to hard-code STRENGTH GOAL values:')
                benchmark = BENCHMARK_DEFAULTS['strengthGoal']
            } else {
                console.log('user HAS set a NON-STRENGTH goal -> benchmark set to hard-code GENERIC GOAL values:')
                benchmark = BENCHMARK_DEFAULTS['otherGoal']
            }
        } else {
            console.log('user HAS NOT set a goal -> benchmark set to hard-code DEFAULT values:')
            benchmark = BENCHMARK_DEFAULTS['noGoal']
        }
    }

    console.log('FINAL VALUES:', benchmark)

    return benchmark
}

function getChartData(nutrition, activity, user, benchmarks) {
    let result = []
    let dt = moment()

    let day_nut, day_cal, day_act, act_cal, entry, bmr
    for (let i = 0; i < 14; i++) {
        day_nut = nutrition.filter(({ dateTime }) => moment(dateTime).isSame(dt, 'date'))
        day_cal = day_nut.reduce((t, { meals }) => (t += meals.reduce((mt, { calories }) => (mt += calories ?? 0), 0)), 0)

        bmr = day_nut.reduce((t, d) => (t += d.burnedCalories), 0) || getBMR(user)

        day_act = activity.filter(({ dateTime }) => moment(dateTime).isSame(dt, 'date'))
        act_cal = day_act.reduce((t, d) => (t += d?.totalCalories ?? 0), 0)

        entry = {
            date: dt.format('DD/MM'),
            'Net calories': 0,
            'Total Protein': 0,
            'Protein delta': 0,
            'Total Carbs': 0,
            'Carbs delta': 0,
        }

        if (day_cal > 1000) {
            entry['Net calories'] = day_cal - (bmr + act_cal)

            let _protein = day_nut.reduce((t, { meals }) => (t += meals.reduce((mt, { protein }) => (mt += protein ?? 0), 0)), 0)
            entry['Total Protein'] = _protein
            entry['Protein delta'] = _protein - benchmarks.protein

            let _carbs = day_nut.reduce((t, { meals }) => (t += meals.reduce((mt, { carbohydrate }) => (mt += carbohydrate ?? 0), 0)), 0)
            entry['Total Carbs'] = _carbs
            entry['Carbs delta'] = _carbs - benchmarks.carbs
        }

        // entry['Net calories'] = Math.round(Math.random() * 1000 - 500)
        // entry['Protein'] = Math.round(Math.random() * 60 - 30)
        // entry['Carbs'] = Math.round(Math.random() * 60 - 30)

        result.push(entry)

        dt = dt.subtract(1, 'days')
    }

    return result.reverse()
}

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        payload = payload[0]

        return (
            <div className='p-4 border border-slate-500 bg-theme-mg'>
                <p className='label'>{label}</p>
                <p>{payload.value === 0 ? 'Need more data' : `${payload.name}: ${Math.round(payload.value)}${payload.name === 'Net calories' ? 'kcal' : 'g'}`}</p>
                {payload.name === 'Protein delta' && <p>{`Total Protein: ${Math.round(payload.payload['Total Protein'])}g`}</p>}
                {payload.name === 'Carbs delta' && <p>{`Total Carbs: ${Math.round(payload.payload['Total Carbs'])}g`}</p>}
            </div>
        )
    }

    return null
}
