import moment from 'moment'
import { useEffect, useState } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

import AppHeader from '../components/app-header'
import AppSidebarLeft from '../components/app-sidebar-left'
import AppSidebarRight from '../components/app-sidebar-right'
import ChatController from '../components/chat'

import CardioActivityForm from '../forms/cardio-activity-form'
import GoalForm from '../forms/goal-form'
import StrengthActivityForm from '../forms/strength-activity-form'

import ImportCard from '../app/import'
import { HeightsProvider, useHeights } from '../context'

export const NAV_LINKS = [
    { name: 'home', link: '/' },
    { name: 'activities', link: '/activities' },
    { name: 'observations', link: '/observations' },
    { name: 'today', link: `/day/${moment().format('YYYY-MM-DD')}` },
]

export default function AppLayout() {
    return (
        <HeightsProvider>
            <WrappedAppLayout />
        </HeightsProvider>
    )
}

function WrappedAppLayout() {
    const location = useLocation()

    const { setSidebarChildren, reload } = useHeights()

    const [showSidebar, setShowSidebar] = useState(false)
    const [formState, setFormState] = useState({ isOpen: false, form: '' })

    useEffect(() => {
        // setSidebarChildren(null)
        setFormState({ isOpen: false, form: '' })
    }, [location])

    function formCallback() {
        reload()
        setFormState({ isOpen: false, form: '' })
    }

    return (
        <>
            <div className='relative flex w-screen h-screen bg-theme-mg'>
                <AppSidebarLeft showSidebar={showSidebar} setShowSidebar={setShowSidebar} formState={formState} setFormState={setFormState}></AppSidebarLeft>

                <main className='relative flex flex-col flex-1 overflow-y-auto'>
                    <AppHeader showSidebar={showSidebar} setShowSidebar={setShowSidebar}></AppHeader>

                    <div className='flex flex-1'>
                        <div className='flex-1 pl-4'>
                            {!formState.isOpen && <Outlet></Outlet>}

                            {formState.isOpen && (
                                <div className='px-4 py-6'>
                                    {formState.form === 'cardio-activity' && <CardioActivityForm id='new' header={<div className='flex justify-between'>New Cardio Activity</div>} callback={() => formCallback()} />}
                                    {formState.form === 'strength-activity' && <StrengthActivityForm id='new' header={<div className='flex justify-between'>New Strength Activity</div>} callback={() => formCallback()} />}
                                    {formState.form === 'meals' && <Navigate to={`/nutrition/${moment().format('YYYY-MM-DD')}`} />}
                                    {formState.form === 'goals' && <GoalForm id='new' callback={() => formCallback()} />}
                                    {formState.form === 'import' && <ImportCard callback={() => formCallback()} />}
                                </div>
                            )}
                        </div>

                        <AppSidebarRight />
                    </div>
                </main>
            </div>
            <ChatController />
        </>
    )
}
