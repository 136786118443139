import moment from 'moment'
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import ErrorBoundary from '../components/error-boundary'
import { AppStickyPageHeader } from '../components/page-header'
import { IconBtn } from '../components/theme-button'

import CardioActivityForm from '../forms/cardio-activity-form'
import FormModal from '../forms/form-modal'
import StrengthActivityForm from '../forms/strength-activity-form'

import { useHeights } from '../context'

export default function ActivityView(props) {
    const params = useParams()
    const navigate = useNavigate()
    const id = params.id ?? props.id

    const { activityData } = useHeights()
    const [activity, setActivity] = useState(null)
    const [openCopy, setOpenCopy] = useState(false)

    useEffect(() => {
        setActivity(activityData.find((d) => d._id === id))
    }, [activityData, id])

    if (!activity) return <></>

    return (
        <>
            <FormModal open={openCopy} setOpen={() => setOpenCopy(!openCopy)}>
                {activity.type === 'strength' && <StrengthActivityForm id='new' className='max-w-lg min-w-[28rem] p-4' header={`Copy of ${activity.title}`} defaultValue={{ ...activity, _id: undefined, dateTime: new Date() }} callback={() => setOpenCopy(false)} />}
                {activity.type !== 'strength' && <CardioActivityForm id='new' className='max-w-lg min-w-[28rem] p-4' header={`Copy of ${activity.title}`} defaultValue={{ ...activity, _id: undefined, dateTime: new Date() }} callback={() => setOpenCopy(false)} />}
            </FormModal>
            <AppStickyPageHeader onBack={() => navigate(-1)}>{moment(activity.dateTime).format('MMMM Do YYYY')}</AppStickyPageHeader>
            <div className='flex flex-col px-1 pb-16 sm:px-6'>
                <ErrorBoundary componentName='Activity Form'>
                    {activity.type === 'strength' && <StrengthActivityForm id={id} header={<FormHeader activity={activity} onCopy={() => setOpenCopy(true)} />} />}
                    {activity.type !== 'strength' && <CardioActivityForm id={id} header={<FormHeader activity={activity} onCopy={() => setOpenCopy(true)} />} />}
                </ErrorBoundary>
            </div>
        </>
    )
}

const FormHeader = ({ activity, onCopy }) => {
    return (
        <div className='flex items-center justify-between flex-1 text-lg h-11'>
            {activity?.title ?? 'Activity Detail'}
            <div className='flex items-center gap-2'>
                {activity.type && <span className='px-4 py-1 text-sm text-white rounded-md bg-neutral-800'>{activity.type.toUpperCase()}</span>}
                {activity?.totalCalories > 0 && <span className='px-4 py-1 ml-2 text-sm text-white rounded-md bg-neutral-800'>{`${Math.round(activity.totalCalories)} cals`}</span>}
                <IconBtn icon={<DocumentDuplicateIcon />} onClick={() => onCopy()} />
            </div>
        </div>
    )
}
