import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import AuthLayout from './layout'

export default function RequestReset() {
    const navigate = useNavigate()
    const [values, setValues] = useState({})
    const [error, setError] = useState('')
    const [submitted, setSubmitted] = useState(false)

    async function submit() {
        await fetch(`${process.env.REACT_APP_API_URL}/auth/request-token`, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
            }),
            body: JSON.stringify(values),
        }).then((x) => {
            setSubmitted(true)
        })
    }

    if (submitted) {
        return (
            <AuthLayout>
                <div className='text-xl font-medium text-center text-neutral-800'>Instructions sent</div>
                <div className='my-5 text-sm font-light text-center text-neutral-500'>If that email address exists, instructions for resetting your password have been sent to your email.</div>
                <div className='my-5 text-sm font-light text-center text-neutral-500'>You'll receive this email within 5 minutes. Be sure to check your spam folder, too.</div>
                <div className='space-y-6'>
                    <div>
                        <button onClick={() => navigate('/login')} type='button' disabled={!values.email || values.email !== values.confirmEmail} className='flex justify-center w-full px-4 py-2 font-semibold text-white bg-blue-500 border border-transparent rounded shadow-sm text-md hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'>
                            Go back to login
                        </button>
                    </div>
                </div>
            </AuthLayout>
        )
    }

    return (
        <AuthLayout>
            <div className='text-xl font-medium text-center text-neutral-800'>Forgot password?</div>
            <div className='my-5 text-sm font-light text-center text-neutral-500'>Enter the email address associated with your account, and we'll email you a link to reset your password.</div>
            <div className='space-y-6'>
                <div>
                    <label className='block text-sm font-medium text-center text-neutral-800'>{error}</label>
                </div>
                <div>
                    <label htmlFor='email' className='block text-sm font-medium text-neutral-800'>
                        Email address
                    </label>
                    <div className='mt-1'>
                        <input value={values.email} onChange={(e) => setValues({ ...values, email: e.target.value })} id='email' name='email' type='email' autoComplete='email' required className='block w-full px-3 py-2 border rounded shadow-sm appearance-none border-neutral-300 placeholder-neutral-400 focus:border-neutral-500 focus:outline-none focus:ring-neutral-500 sm:text-sm' />
                    </div>
                </div>
                <div>
                    <label htmlFor='email' className='block text-sm font-medium text-neutral-800'>
                        Confirm Email address
                    </label>
                    <div className='mt-1'>
                        <input value={values.confirmEmail} onChange={(e) => setValues({ ...values, confirmEmail: e.target.value })} id='email' name='email' type='email' autoComplete='email' required className='block w-full px-3 py-2 border rounded shadow-sm appearance-none border-neutral-300 placeholder-neutral-400 focus:border-neutral-500 focus:outline-none focus:ring-neutral-500 sm:text-sm' />
                    </div>
                </div>

                <div>
                    <button onClick={() => submit()} type='button' disabled={!values.email || values.email !== values.confirmEmail} className='flex justify-center w-full px-4 py-2 font-semibold text-white bg-blue-500 border border-transparent rounded shadow-sm text-md hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'>
                        Send reset link
                    </button>
                    <div className='my-5 text-sm text-center text-neutral-500'>
                        Go back to{' '}
                        <a className='text-blue-400 hover:bg-neutral-300/50 transition' href='/login'>
                            Sign In
                        </a>
                    </div>
                </div>
            </div>
        </AuthLayout>
    )
}
