import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { api, api_delete } from '../services/api.service'

import Button from '../components/button'
import Confirm from '../components/confirm'
import Loading from '../components/loading'
import PageHeader from '../components/page-header'
import Table from '../components/table'

export default function AdminUsers() {
    const navigate = useNavigate()
    const [users, setUsers] = useState(null)
    const [deleteId, setDeleteId] = useState()

    useEffect(() => {
        api(`${process.env.REACT_APP_API_URL}/admin/user`).then((x) => setUsers(x))
    }, [])

    function deleteConfirm() {
        api_delete(`${process.env.REACT_APP_API_URL}/admin/user/${deleteId}`).then((x) => {
            api(`${process.env.REACT_APP_API_URL}/admin/user`)
                .then((x) => setUsers(x))
                .then((x) => setDeleteId(null))
        })
    }

    if (!users) return <Loading></Loading>

    return (
        <>
            <Confirm open={!!deleteId} cancel={() => setDeleteId(false)} confirm={() => deleteConfirm(deleteId)}></Confirm>

            <PageHeader headline={'Users'} actions={<Button text='Add User' onClick={() => navigate('/admin/manage-user/new')}></Button>}></PageHeader>

            <div className='flex'>
                <div className='w-full max-w-5xl pb-20 mx-auto my-10 2xl:max-w-6xl'>
                    <div>
                        <Table
                            exportColumns={['Email']}
                            exportData={users.map((x) => {
                                return {
                                    email: x.email,
                                }
                            })}
                            data={users.map((x) => {
                                return {
                                    ...x,
                                    edit: (
                                        <span className='underline cursor-pointer' onClick={() => navigate(`/admin/manage-user/${x._id}`)}>
                                            Edit
                                        </span>
                                    ),
                                    deleteCell: (
                                        <span className='underline cursor-pointer' onClick={() => setDeleteId(x._id)}>
                                            Delete
                                        </span>
                                    ),
                                }
                            })}
                            columns={[
                                {
                                    Header: 'Email',
                                    accessor: 'email',
                                },
                                {
                                    Header: 'Edit',
                                    accessor: 'edit',
                                },
                                {
                                    Header: 'Delete',
                                    accessor: 'deleteCell',
                                },
                            ]}
                        ></Table>
                    </div>
                </div>
            </div>
        </>
    )
}
