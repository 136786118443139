import { Combobox, Transition } from '@headlessui/react'
import { ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { Fragment, useEffect, useState } from 'react'

import Loading from '../../components/loading'

export default function SelectField({ label, value, onChange, className, labelClass, options, optionListName, placeholder, required }) {
    const [selectOptions, setSelectOptions] = useState(options)
    const [query, setQuery] = useState('')
    const filtered = query === '' ? selectOptions : selectOptions.filter((x) => x?.text.toLowerCase().includes(query.toLowerCase()))

    useEffect(() => {
        if (optionListName === 'activity_types') {
            setSelectOptions([
                { value: 'run', text: 'RUN' },
                { value: 'walk', text: 'WALK' },
                { value: 'cycle', text: 'CYCLE' },
            ])
        }

        if (optionListName === 'activity_feeling') {
            setSelectOptions([
                { value: 'fire', text: 'Fire' },
                { value: 'didnt_have_it', text: "Didn't have it" },
                { value: 'at_peace', text: 'At Peace' },
                { value: 'lets_go', text: "Let's Gooo" },
                { value: 'grinding', text: 'Grinding' },
            ])
        }
    }, [optionListName])

    if (!selectOptions) return <Loading height='2rem'></Loading>

    return (
        <div className={className}>
            {label && (
                <label className={`block text-sm mb-1 font-medium leading-6 text-neutral-600 ${labelClass}`}>
                    {label} {required && <span className='text-red-500'>*</span>}
                </label>
            )}
            <Combobox
                value={selectOptions.find((x) => x.value === value)}
                onChange={(e) => {
                    onChange(e)
                }}
            >
                <div className='relative h-full text-left cursor-default focus:outline-none'>
                    <Combobox.Input placeholder={placeholder} className='w-full h-full py-2 pl-3 pr-10 text-sm leading-5 border-none bg-slate-100 text-neutral-700' onChange={(e) => setQuery(e.target.value)} displayValue={(x) => x?.text} required={required} />
                    <Combobox.Button className='absolute inset-y-0 right-0 flex items-center pr-2'>
                        <ChevronUpDownIcon className='w-5 h-5 text-gray-400' aria-hidden='true' />
                    </Combobox.Button>
                </div>
                <div className='relative'>
                    <Transition as={Fragment} leave='transition ease-in duration-100' leaveFrom='opacity-100' leaveTo='opacity-0' afterLeave={() => setQuery('')}>
                        <Combobox.Options className='absolute z-[100] w-full py-1 mt-1 overflow-visible text-base rounded shadow-lg bg-white max-h-60 focus:outline-none'>
                            {filtered.map((x) => (
                                <Combobox.Option key={x.value} value={x.value} className={({ active }) => `relative cursor-default select-none py-2 px-3  ${x.value === value ? 'bg-blue-600 text-white' : `${x.value !== value && active ? 'bg-blue-400 text-white' : 'text-neutral-700'}`} `}>
                                    {x.text}
                                </Combobox.Option>
                            ))}
                        </Combobox.Options>
                    </Transition>
                </div>
            </Combobox>
        </div>
    )
}
