import { ArrowPathIcon } from '@heroicons/react/24/outline'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { api } from '../services/api.service'

import Card from '../components/card'
import { AppStickyPageHeader } from '../components/page-header'
import ThemeButton, { TrashBtn } from '../components/theme-button'

import { DateField, NumberField, TextAreaField, TextField } from '../forms/fields'
import FormWrapper from '../forms/form-wrapper'

import { useHeights } from '../context'

export default function NutritionView(props) {
    const navigate = useNavigate()
    const params = useParams()

    console.log(params)

    const { nutritionData, updateNutrition, reload } = useHeights()

    const [data, setData] = useState()

    useEffect(() => {
        let _dt = moment(params.date)
        let _data = nutritionData.find((d) => moment(d.dateTime).isSame(_dt, 'date'))
        if (_data) {
            setData(_data)
        } else {
            setData({ dateTime: _dt.toDate(), meals: [], dayTotalCalories: 0 })
        }
    }, [nutritionData, params.date])

    async function getEstimate(meal) {
        let response = await api(`${process.env.REACT_APP_API_URL}/chat/estimate-nutrition`, { data: meal })
        let content = response.messages[response.messages.length - 1].content
        let estimate = null
        try {
            estimate = JSON.parse(content)
            return estimate
        } catch {
            console.info('[ PROMPT RETURN ] Calorie estimate error catch with response: ', content)
            alert(content)
        }
        return estimate
    }

    async function onAddNewMeal(newMeal) {
        let estimate = await getEstimate(newMeal)
        if (estimate) {
            newMeal.calories = estimate?.calories ?? 0
            newMeal.carbohydrate = estimate?.carbohydrate ?? 0
            newMeal.protein = estimate?.protein ?? 0
            newMeal.fat = estimate?.fat ?? 0
        }
        updateNutrition({ ...data, dateTime: moment(params.date).toDate(), meals: [...(data?.meals ?? []), newMeal] })
    }

    return (
        <>
            <AppStickyPageHeader onBack={() => navigate(-1)}>{moment(params.date).format('MMMM Do YYYY')}</AppStickyPageHeader>
            <div key={'nutrition_form_' + params.date} className='mx-1 mb-10 sm:mx-6'>
                {JSON.stringify(params)}
                <Card header={data ? 'Nutrition Details' : 'Add Nutrition'}>
                    <div className='m-4 divide-y divide-slate-700 min-w-fit divide-dotted'>
                        <FormWrapper
                            url='app/nutrition'
                            id={data?._id ?? 'new'}
                            noStyle
                            hideButton
                            defaultValue={{ dateTime: moment(params.date).toDate() }}
                            callback={() => {
                                reload()
                            }}
                            {...props}
                        >
                            {(values, setValues, isChanged) => (
                                <>
                                    <div className='flex pb-2 space-x-4'>
                                        <DateField
                                            label='DAY DATE'
                                            value={values.dateTime}
                                            onChange={(val) => {
                                                navigate(`/nutrition/${moment(val).format('YYYY-MM-DD')}`)
                                            }}
                                        />
                                        <div className='flex items-end justify-between flex-1 mb-2'>
                                            <NumberField value={values?.burnedCalories} onChange={(val) => setValues({ ...values, burnedCalories: val })} label='BMR BURNED CALORIES' />
                                            {isChanged && <ThemeButton type='submit'>Save</ThemeButton>}
                                        </div>
                                    </div>
                                    <div className='flex my-2'>
                                        <div>Day Total Consumed Calories: {values?.meals?.reduce((tot, d) => (tot += d.calories), 0)}</div>
                                        <div>Day Total Carbs: {values?.meals?.reduce((tot, d) => (tot += d.carbohydrate), 0)}g</div>
                                        <div>Day Total Protein: {values?.meals?.reduce((tot, d) => (tot += d.protein), 0)}g</div>
                                        <div>Day Total Fat: {values?.meals?.reduce((tot, d) => (tot += d.fat), 0)}g</div>
                                    </div>
                                    <ul className='divide-y divide-slate-700 divide-dotted'>
                                        {values?.meals?.map((meal, idx) => (
                                            <li key={meal + idx} className='flex flex-col justify-center mb-2'>
                                                <div className='flex items-end space-x-2 '>
                                                    <TextField value={meal.title} onChange={(val) => setValues({ ...values, meals: values.meals.map((d, i) => (i === idx ? { ...meal, title: val } : d)) })} label='TITLE' className='flex-1' />
                                                    <NumberField value={meal.calories} onChange={(val) => setValues({ ...values, meals: values.meals.map((d, i) => (i === idx ? { ...meal, calories: Number(val) } : d)) })} label='CALORIES' className='flex-1' />
                                                    <ArrowPathIcon
                                                        id={`refresh-estimate-meal-${idx}`}
                                                        className={`w-6 h-6 mb-2 cursor-pointer text-neutral-800 hover:bg-neutral-300/50 transition focus:animate-spin}`}
                                                        onClick={async (e) => {
                                                            let estimate = await getEstimate(meal)
                                                            if (estimate) {
                                                                setValues({ ...values, meals: values.meals.map((d, i) => (i === idx ? { ...meal, ...estimate } : d)) })
                                                            }
                                                        }}
                                                    />
                                                    <span className='flex flex-col justify-end mb-2'>
                                                        <TrashBtn
                                                            onClick={() => {
                                                                setValues({ ...values, meals: values.meals.filter((_, i) => i !== idx) })
                                                                updateNutrition({ ...values, meals: values.meals.filter((_, i) => i !== idx) })
                                                            }}
                                                        />
                                                    </span>
                                                </div>
                                                <div className='grid grid-cols-3 gap-2 mt-2'>
                                                    <NumberField value={meal.carbohydrate} onChange={(val) => setValues({ ...values, meals: values.meals.map((d, i) => (i === idx ? { ...meal, carbohydrate: Number(val) } : d)) })} label='CARBS' className='flex-1' />
                                                    <NumberField value={meal.protein} onChange={(val) => setValues({ ...values, meals: values.meals.map((d, i) => (i === idx ? { ...meal, protein: Number(val) } : d)) })} label='PROTEIN' className='flex-1' />
                                                    <NumberField value={meal.fat} onChange={(val) => setValues({ ...values, meals: values.meals.map((d, i) => (i === idx ? { ...meal, fat: Number(val) } : d)) })} label='FAT' className='flex-1' />
                                                </div>
                                                <TextAreaField label='DETAILS' value={meal.details} onChange={(val) => setValues({ ...values, meals: values.meals.map((d, i) => (i === idx ? { ...meal, details: val } : d)) })} />
                                            </li>
                                        ))}
                                    </ul>
                                    <NewMealFormBlock
                                        onSubmit={(newMeal) => {
                                            setValues({ ...values, meals: [...(values?.meals ?? []), newMeal] })
                                            onAddNewMeal(newMeal)
                                        }}
                                    />
                                </>
                            )}
                        </FormWrapper>
                    </div>
                </Card>
            </div>
        </>
    )
}

const NewMealFormBlock = ({ onSubmit }) => {
    const [state, setState] = useState({
        title: '',
        details: '',
        calories: 0,
    })

    return (
        <div className='flex flex-col pt-4'>
            <div className='flex items-center justify-between'>
                <div>Add New Meal</div>
                <ThemeButton
                    onClick={(e) => {
                        e.preventDefault()
                        onSubmit(state)
                        setState({
                            title: '',
                            details: '',
                            calories: 0,
                        })
                    }}
                    disabled={state.title.trim() === ''}
                    className='mt-4 disabled:opacity-40 disabled:shadow-none'
                >
                    Add
                </ThemeButton>
            </div>
            <TextField value={state.title} onChange={(val) => setState({ ...state, title: val })} label='Title' className='col-span-2' />
            <TextAreaField value={state.details} onChange={(val) => setState({ ...state, details: val })} label='Details' />
        </div>
    )
}
